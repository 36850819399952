import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { SnotifyService } from "ng-snotify";
import { UserAuthService } from "./user-auth.service";

@Injectable({
  providedIn: "root",
})
export class AutologoutService {
  isLogin = false;
  constructor(
    private router: Router,
    private snotifyService: SnotifyService,
    private ngZone: NgZone,
    private userauthservice: UserAuthService
  ) {
    if (userauthservice.isUserLoggedIn()) {
      this.isLogin = true;
    }
    this.lastAction(Date.now());
    this.check();
    this.initListener();
    this.initInterval();
  }

  /**
   * last action
   */
  getLastAction() {
    return localStorage.getItem("lastAction");
  }

  /**
   * set last action
   * @param value
   */
  lastAction(value: any) {
    localStorage.setItem("lastAction", JSON.stringify(value));
  }

  /**
   * start event listener
   */
  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener("click", () => this.reset());
    });
  }

  /**
   * time interval
   */
  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, 1000);
    });
  }

  /**
   * reset timer
   */
  reset() {
    this.lastAction(Date.now());
  }

  /**
   * check timer
   */
  check() {
    //console.log('sasdcasdas');
    const now = Date.now();
    let time: any = this.getLastAction();
    const timeLeft = parseInt(time) + 50 * 60 * 1000;
    //console.log(timeLeft);
    const diff = timeLeft - now;
    const isTimeout = diff < 0;
    //this.isLoggedIn.subscribe(event => this.isLogin = event);
    this.ngZone.run(() => {
      if (isTimeout && this.isLogin) {
        //console.log('hello');
        localStorage.removeItem("user");
        localStorage.removeItem("lastAction");
        setTimeout(() => {
          //console.log("Your Session Expired due to longer Inactivity, Login Again To Continue");
        }, 10000);
        this.router.navigate(["signin"]);
      }
    });
  }

  /**
   *check if a user is logged in
   */
}
