    <div class="dropdown lang-dropdown">
    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
        aria-expanded="false">
        <!--<img src="assets/img/{{countryFlag}}" alt="">-->
        <span style="color:#000;">{{countryFlag}}</span>
    </button>
    <ul class="dropdown-menu">
      <li (click)="languageSwitcherAction('en-US','EN')"><a class="dropdown-item" href="javascript:void(0);">
        <!--<span><img i18n-alt src="assets/img/flag01.png" alt="USA"></span>-->
        <ng-container i18n>English</ng-container>
      </a>
    </li>
      <li (click)="languageSwitcherAction('fr-CA','FR')">
        <a class="dropdown-item" href="javascript:void(0);">
       <!-- <span><img i18n-alt src="assets/img/flag03.png" alt="France"></span>-->
        <ng-container i18n="@@langtranslatefff1">French</ng-container> </a></li>
      <!-- <li ><a class="dropdown-item" href="javascript:void(0);"><span ><img i18n-alt src="assets/img/flag04.png" alt="Italy"></span> <ng-container i18n="@@langtranslatefff2">Italian</ng-container> </a></li>
      <li class="active"><a class="dropdown-item" href="javascript:void(0);"><span><img i18n-alt src="assets/img/flag05.png" alt="Portugal"></span> <ng-container i18n>Portuguese</ng-container> </a></li>
      <li><a class="dropdown-item" href="javascript:void(0);"><span><img i18n-alt src="assets/img/flag02.png" alt="Albania"></span> <ng-container i18n>Albanian</ng-container> </a></li> -->
  </ul>
</div>