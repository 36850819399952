import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { AuthGuardGuard } from './_services/auth/auth-guard.service';


const routes: Routes = [{
  path: 'user',
  loadChildren: () => import('./user-admin/user-admin.module').then(m => m.UserAdminModule)
  //canActivate: [AuthGuardGuard]
}, {
  path: '',
  loadChildren: () => import('./frontend/frontend.module').then(m => m.FrontendModule)
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
