import { Component, AfterViewInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router} from '@angular/router';
import { UserAuthService } from '../../_services/user-auth.service';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  // standalone: true,
	// imports: [NgbDropdownModule, NgbAccordionModule]
})
export class SidebarComponent implements AfterViewInit {
  @ViewChild('privacypolicy', { static: true }) privacypolicy: TemplateRef<any>;
  _signOutSub:any;
  _user:any;
  logoutErrorMessage:any;
  currentUrl: string;
  constructor(private modalService: NgbModal, private router:Router, private userAuthService:UserAuthService){
    this.getCurrentUser();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    })
  }

  ngAfterViewInit(): void {
    $(document).on('click','.nav-item>a', function(e){
      $(this).parent().toggleClass('menu-open').siblings().removeClass('menu-open');
    })

  }

  PrivacyPolicyModal(content:any) {
		this.modalService.open(content, { centered: true, modalDialogClass: 'privacy-modal', size:'xl', backdrop: 'static' });
	}
  getCurrentUser(): any {
    let data = localStorage.getItem('user');
    if (data != null) {
      this._user = JSON.parse(data);
      // console.log(this._user);
    }
    return this._user;
  }
  SignOut(): any {
    //localStorage.clear();
    localStorage.removeItem('user');
    this._signOutSub = this.userAuthService.signout(
      {
        token:this._user.token,
        hardSignOut:true
      }
    ).subscribe({
      next: (x: any) => {
        localStorage.removeItem('user');
        localStorage.clear();

        setTimeout(() => {
          this.router.navigate(['/signin']);
      }, 2000);
        
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
        // console.error(err)
        localStorage.clear();
        this.router.navigate(['/signin']);
        this.logoutErrorMessage = errRes.error.message;
      },
      complete: () => {
        localStorage.removeItem('user');
      },
    });
// console.log("hello");

   
     
   
  }
}
