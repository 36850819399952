
<aside class="aside-navbar">
  <div class="aside-top">
    <figure class="sidebar-logo">
      <img src="assets/img/logo-white.png" alt="">
      <button id="close_sidebar" class="d-md-none"><i class="bi bi-x"></i></button>
    </figure>
    
    <ul>
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl=='/user' }"><a href="javascript:void(0);"  routerLink="/user"><span><i class="bi bi-grid-fill"></i></span><ng-container i18n> Dashboard</ng-container></a></li>
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/user/request-proposal') }"><a  href="javascript:void(0);" routerLink="/user/request-proposal"><span><i class="bi bi-file-earmark-text-fill"></i></span><ng-container i18n> Request For Proposal</ng-container></a></li>
      <li class="nav-item has-dropdown">
        <a href="javascript:void(0);"><span><i class="bi bi-file-text-fill"></i></span><ng-container i18n> Contract & Proposal</ng-container></a>
          <ul>
            <li><a href="javascript:void(0);" [ngClass]="{ 'dash-active': currentUrl.includes('/user/personalised-offer') }" routerLink="/user/personalised-offer"><ng-container i18n="@@ddbrfff9876">Current & completed offers</ng-container></a></li><!--Pesonalised offer, Current offers, Offres en cours & terminée-->
            <li><a href="javascript:void(0);" [ngClass]="{ 'dash-active': currentUrl.includes('/user/claim') }" routerLink="/user/claim"><ng-container i18n>Claim</ng-container></a></li><!--General Offer-->
            <li><a href="javascript:void(0);" [ngClass]="{ 'dash-active': currentUrl.includes('/user/general-offer') }" routerLink="/user/general-offer"><ng-container i18n>General Offer</ng-container></a></li><!--General Offer-->
            <li><a href="javascript:void(0);" [ngClass]="{ 'dash-active': currentUrl.includes('/user/contracts') }" routerLink="/user/contracts" ><ng-container i18n="@@8850565226719364458">Active policies</ng-container></a></li>
            <li><a href="javascript:void(0);" [ngClass]="{ 'dash-active': currentUrl.includes('/user/documents') }" routerLink="/user/documents"><ng-container i18n="@@1611247386028881485">Documents & history</ng-container></a></li>
          </ul>
      </li>
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/user/message') }"><a href="javascript:void(0)" routerLink="/user/message"><span><i class="bi bi-envelope-open-fill"></i></span><ng-container i18n> Message</ng-container></a></li>
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/user/family-members') }"><a href="javascript:void();" routerLink="/user/family-members"><span><i class="bi bi-person-bounding-box"></i></span><ng-container i18n> Member's profile</ng-container></a></li>
      <li class="nav-item"><a href="javascript:void();" (click)="PrivacyPolicyModal(privacypolicy)"><span><i class="bi bi-person-bounding-box"></i></span><ng-container i18n="@@privacypolicy">Privacy Policy</ng-container></a></li>
      <li class="nav-item"><a href="javascript:void(0)" (click)="SignOut()"><span><i class="bi bi-power"></i></span><ng-container i18n> Logout</ng-container></a></li>
    </ul>
  
  </div>
 
</aside>

<ng-template #privacypolicy let-modal >
  <div class="modal-header">
    <div class="h5"><i class="bi bi-journals"></i> politique de confidentialité</div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <ng-scrollbar class="custom-scrl" style="height:400px!important;">
      <div class="h3 mb-3">Déclaration de protection des données (19 LPD)</div>
      <div class="h4">1.	Quel est l'objet de cette déclaration de protection des données ?</div>
      <p>La société LTY SA (CHE-277.025.680 - <a href="https://www.zefix.ch/fr/search/entity/list/firm/1439061" target="_blank">www.zefix.ch/fr/search/entity/list/firm/1439061</a>) est une société anonyme de droit suisse avec siège social à CH-1950 Sion (pour adresse : Chemin St-Hubert 40, CH-1950 Sion). Elle collecte et traite des données personnelles (notamment celles relatives à votre visite sur notre site internet), en conformité avec les dispositions légales, dont la loi fédérale sur la protection des données (du 25 septembre 2020 ; abrégée ci-après LPD). Ainsi sont notamment collectées et traitées les données relatives à nos clients / utilisateurs de notre site et/ou de nos services, des personnes ou entités qui leur sont corrélées, des abonnées à notre lettre d'information, ainsi que de toute personne et/ou entité avec laquelle nous interagissons dans le cadre du but de la société qui est le suivant:</p>
      <p><i>Le conseil et le courtage dans les domaines de l'immobilier, des assurances, de l'investissement, des crédits hypothécaires ou privés pour des personnes morales ou physiques, ainsi que toutes activités immobilières et financières en Suisse et à l'étranger</i></p>
      <p>La présente déclaration a donc pour objectif principal de vous orienter sur ce que nous entreprenons relativement ou à l'égard de vos données personnelles. Notre Conseiller à la protection des données (et annoncé comme tel auprès du Préposé fédéral à la protection des données) est Me Sébastien Fanti, avocat spécialisé en cette matière. Celui-ci peut être joint grâce à l'adresse de courriel suivante : <a href="mailto:sebastien.fanti@datacompliance.ch">sebastien.fanti@datacompliance.ch</a></p>
      <p>En sus de la présente déclaration de protection des données, nos différents formulaires, contrats ou conditions générales comportent des clauses relatives au traitement de vos données. Nous nous efforçons de manière permanente d'améliorer le respect de vos droits à l'aune de l'évolution des contraintes régulatoires et de l'efficience de nos activités. Dans ce contexte, n'hésitez pas à nous soumettre vos éventuelles remarques et/ou observations.</p>
      <p>La présente déclaration de confidentialité est disponible en français, allemand, italien et anglais. En cas de discrépance, seule la version française fait foi.</p>
      <div class="h4 mt-4">2.	Responsabilités relatives au(x) traitement(s) de vos données :</div>
      <p>La société LTY SA est responsable de la collecte et du traitement de vos données, en tant qu'elle exploite le site internet <a href="https://lty.ch/" target="_blank">www.lty.ch</a> et les services qui y sont proposés, respectivement associés. Si sur notre site, une autre entité /personne collecte des données personnelles de manière indépendante, nous vous l'indiquerons explicitement. Dans cette hypothèse, c'est cette entité / personne qui est responsable du traitement des données.</p>
      <p>Vos données sont stockées en Suisse sur l'un des serveurs de la société Amazon Web Services Switzerland SA (CHE-433.373.616 ; AWS). Pour de plus amples informations sur le traitement de vos données personnelles par AWS, nous vous invitons à lire l' « Avis concernant la protection des données » disponible en français à cette adresse : <a href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_French_2023-08-11.pdf" target="_blank">https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_French_2023-08-11.pdf</a> Le contenu de cet avis est susceptible d'évoluer avec le temps, à l'instar de la présente déclaration de protection des données. </p>
      <p>Il convient également de vous indiquer que vous êtes responsables des données relatives à des tiers que vous nous communiquez. Cela signifie que nous partons du principe que vous êtes spécifiquement et préalablement autorisé(e)s à le faire, que les données sont correctes et que vous avez informé les personnes / entités concernées de ce transfert en conformité avec les normes légales. Il est à cet égard rappelé qu'une communication illicite de données est susceptible d'engendrer des conséquences légales importantes. L'usurpation d'identité est, à titre exemplatif, réprimée en Suisse. Selon l'article 179decies du Code pénal, quiconque se rend coupable d'une usurpation d'identité s'expose à une peine pécuniaire ou à une peine privative de liberté.</p>
      <p>En cas de doute à ce sujet, vous pouvez sans autre nous contacter aux fins que nous puissions vous orienter relativement à ce qu'il convient d'entreprendre. Nous vous assisterons avec plaisir.</p>
      <div class="h4 mt-4">3.	Dans quel but collectons-nous et traitons-nous vos données ?</div>
      <p>Lorsque vous naviguez sur le site (<a href="https://lty.ch/" target="_blank">www.lty.ch</a>), que vous utilisez et / ou acquérez nos services / produits ou ceux de tiers, que vous vous inscrivez à notre lettre d'information, soit en bref que vous interagissez avec nous numériquement, nous collectons et traitons différentes données vous concernant. Nous nous efforçons, en tout temps de ne collecter que les données strictement nécessaires à notre activité (principe de minimisation des données). </p>
      <p>Voici quelques-uns des buts que nous poursuivons lorsque nous traitons des données, étant précisé que plusieurs d'entre eux peuvent trouver application simultanément, auquel cas nous procédons à une pondération. En définitive, nous avons toujours le souci de ne collecter, respectivement de ne traiter que ce qui est nécessaire. </p>
      <ul class="points">
        <li><u>Offre de différents services en ligne</u> : comme vous l'avez constaté lorsque vous vous êtes rendus sur notre site (<a href="https://lty.ch/" target="_blank">www.lty.ch</a>), nous offrons différents services en ligne (demandes d'offres, offres personnalisées, gestion des contrats, gestion des sinistres, messagerie, etc.), lesquels sont susceptibles, de surcroît, d'évoluer ; pour vous offrir des telles prestations que nous souhaitons optimales, nous collectons différentes données personnelles vous concernant (par exemple au moyen de formulaires) et traitons ces données, en particulier pour faire suite à vos requêtes (demande d'offres, annonce de sinistre, etc.) ; dans ce contexte et dès lors que nous collaborons avec différentes compagnies d'assurance, il est possible que nous collections également des données personnelles vous concernant auprès de tiers ; </li>
        <li><u>Communication</u> : pour vous offrir un service personnalisé de haute qualité, nous utilisons plusieurs moyens de communication (messagerie intégrée au site, courriels, téléphone, courrier, etc.) ; dans le cadre de ces échanges avec vous, ainsi qu'avec des tiers, nous traitons des données que nous estimons pertinentes de manière à pouvoir, en tout temps, répondre à vos demandes et satisfaire pleinement vos attentes ; ces communications interviennent dans le respect du principe de sécurité des données et nous privilégions, dans ce cadre, les moyens les plus sécurisés ; dans le cadre du processus permanent d'amélioration de nos services, nous sommes susceptibles de procéder à un enregistrement audio ou vidéo de nos échanges, ce dont vous êtes préalablement informé et auquel vous devez consentir ; la loi autorise spécifiquement à l'article 179quiquies, al. 1, let b du Code pénal les enregistrements portant sur des commandes, des mandats, des réservations ou d'autres transactions commerciales de même nature, dans le cadre de relations d'affaires ; ce nonobstant, nous n'opérons jamais de tels enregistrements sans vous avoir informé au préalable et sans avoir recueilli votre consentement ;</li>
        <li><u>Vérification de votre identité</u> : dans le domaine de l'assurance notamment, des règles spécifiques existent en matière d'identification du client ; nous respectons scrupuleusement ces règles émises entre autres par la FINMA (<a href="https://www.finma.ch/fr/" target="_blank">www.finma.ch/fr/</a>) et nous sommes dès lors contraints de collecter différentes données y relatives (copie de pièce d'identité, NAVS13, etc.) ; nous ne le faisons toutefois que dans le cadre strictement nécessaire ; </li>
        <li><u>Relations contractuelles</u> : l'un des objectifs de notre société est de vous permettre d'appréhender votre couverture d'assurance, d'opérer les meilleurs choix et en définitive, d'économiser de l'argent en optant pour le produit adéquat, au meilleur prix ; nous collectons et traitons donc les données nécessaires à la conclusion de nouveaux contrats qu'il s'agisse de contrats avec notre société ou avec des tiers (à titre exemplatif : coordonnées, photographies, procurations, déclarations de consentement, documents d'identité, certificats médicaux, etc.), que ces données vous concernent, concernent votre employeur ou par exemple vos proches ou familiers ; cette collecte peut intervenir par votre intermédiaire ou auprès de tiers (employeur, informations publiques, autorités, etc.), en parfaite transparence, ce qui signifie que vous en êtes informé(e) ;</li>
        <li><u>Gestion ultérieure des contrats</u> : une fois les contrats conclus, nous en assurons la gestion, ce qui signifie que nous interagissons avec des compagnies d'assurance, des prestataires (informatiques, étatiques, etc.) ou d'autres intermédiaires, dans le but de fournir le service qui vous a été promis, respectivement qui a été convenu contractuellement ; cette gestion concerne pragmatiquement de nombreuses données qui vont de la facturation au recouvrement, en passant par la satisfaction client ou encore le suivi de sinistres ; concrètement, nous traitons les données initialement collectées dans le cadre de la conclusion du contrat, nous les complétons et nous les communiquons dans la mesure strictement nécessaire ; </li>
        <li><u>Outils marketing</u> : vos données sont également utilisées à des fins marketing, qu'il s'agisse d'envoi ou d'affichage de publicité personnalisée (relativement à nos services et/ou produits ou des services et/ou produits tiers), d'études de marché ou d'analyses comportementales en ligne ; nous utilisons, notamment, dans ce contexte, les données collectées dans le cadre de notre relation contractuelle ou encore celles liées à l'inscription à notre feuille d'information ; il vous est loisible à tout moment de refuser ces envois, de révoquer votre consentement (en nous adressant un courriel à <a href="mailto:info@lty.ch">info@lty.ch</a> ) ou de vous désinscrire de notre lettre d'information ; les données principalement concernées sont vos noms, prénoms, adresses, courriels, numéros de téléphone ou autres coordonnées ; nous réalisons à des fins d'optimisation de nos services en ligne ou figurant sur notre application des analyses de votre comportement (navigation, interactions avec les réseaux sociaux, utilisation de produits et/ou services) ;</li>
        <li><u>Utilisation de nos services numériques et de notre application</u> : aux fins d'assurer le bon fonctionnement (sécurisé et stable) de l'ensemble de nos activités digitales, qu'il s'agisse du site internet ou de l'application, différentes données techniques principalement sont collectées (adresse IP, informations sur le système d'exploitation, paramètres de votre terminal, données GPS, heure et fuseau horaire, type d'utilisation etc.), de même que des cookies et des technologies similaires (cf. ci-après) ;</li>
        <li><u>Sécurité des systèmes d'information</u> : dans le but d'assurer un niveau adéquat permanent de sécurité des données, tant en ce qui concerne nos systèmes informatiques que nos autres infrastructures (bâtiment comportant un contrôle d'accès par exemple), nous collectons des données personnelles ; il s'agit à titre exemplatif de celles relatives aux accès, de copies de sauvegarde, de tests de sécurité, de logs, etc. </li>
        <li><u>Analyse et gestion des risques</u> : pour protéger notre société, nous collectons et traitons différentes données qui concernent tantôt le fonctionnement courant de notre société (organisation, ressources, personnel, etc.), tantôt sa protection (contre les vols, les actes malveillants, etc.) ; </li>
        <li><u>Divers</u> : les autres buts de collecte sont ceux généralement poursuivi dans le cadre d'une activité similaire à celle de notre société. </li>
      </ul>
      <div class="h4 mt-4">4.	Le profilage et la prise de décision automatisée :</div>
      <p>Le « profilage » désigne toute forme de traitement automatisé de données personnelles servant à évaluer certains aspects personnels relatifs à une personne physique dans la mesure où il vise à analyser ou à prédire des éléments concernant par exemple le rendement au travail, la situation économique, la santé, les préférences personnelles, les intérêts, la fiabilité, le comportement, la localisation ou les déplacements d'une personne physique. Pour simplifier, le profilage consiste en une sorte d'appréciation ou d'évaluation d'une personne. </p>
      <p>L'évaluation peut consister à analyser certaines caractéristiques de la personnalité, mais elle peut aussi être utilisée pour prédire un comportement ou une qualité future d'une personne. À la différence de la décision individuelle automatisée, le traitement des données n'est pas forcément entièrement automatisé lors d'un profilage. L'intervention humaine n'exclut pas qu'il s'agisse d'un profilage tant que le traitement des données est pour l'essentiel automatisé. On parle de « profilage à risque élevé » lorsqu'un profilage entraîne un risque élevé pour la personnalité ou les droits fondamentaux de la personne concernée parce qu'il conduit à un appariement de données qui permet d'apprécier les caractéristiques essentielles de la personnalité d'une personne physique. </p>
      <p>Nous procédons dans le cadre de nos activités dans le champ des assurances à une évaluation de vos caractéristiques personnelles (santé, âge, etc.) visant à déterminer, notamment, quel produit / service sont adéquats et présentent les meilleures opportunités. Des évaluations statistiques ou en termes de marketing sont également possibles sur cette base. Il est également possible que nous automatisions des décisions, notamment en recourant à l'intelligence artificielle. Dans une telle hypothèse, nous vous informerons exhaustivement relativement à vos droits et vous permettrons toujours de bénéficier d'un contact avec l'un de nos collaborateurs. L'humain sera toujours au centre des préoccupations de notre entreprise. </p>
      <div class="h4 mt-4">5.	L'origine des données que nous traitons et leur communication à des tiers :</div>
      <p>Comme vous l'aurez compris à la lecture des articles précédents, nos données proviennent essentiellement de vous (de vos terminaux, de vos communications ou de nos échanges), de tiers (sources publiques, opendata, partenaires, employeur, société d'information financière et de vente de données, autorités, marchands d'adresse, société d'analyse web, etc.). Ces données nous sont nécessaires pour exécuter nos obligations contractuelles, soit obtenir des offres, préparer des contrats, en finaliser la conclusion, les exécuter, etc. Comme déjà dit, nous n'utilisons que les données qui nous sont strictement nécessaires. </p>
      <p>Nous communiquons (dans le cadre des buts déjà évoqués) vos données à différents prestataires de service (informatiques, publicitaires, banques, assurances, sociétés de recouvrement, sociétés d'informations financières, vérificateurs d'adresse, autorités, etc.). Il en va de même en ce qui concerne nos partenaires clairement identifiés et mentionnés sur notre site internet. Ces partenaires reçoivent des données vous concernant dans le cadre des contrats à exécuter, de la coopération que vous entretenons avec eux et ils traitent ces données sous leur propre responsabilité. </p>
      <p>D'autres personnes peuvent également être récipiendaires de vos données. Il peut s'agir de bénéficiaires des polices conclues, de vos représentants (avocat, fiduciaire, banque, etc.), d'autorités ou de personne impliquées dans des procédures administratives ou judiciaires. Il est également possible que notre société procède à des ventes ou des acquisitions d'actifs, qu'elle cède des actions ou en acquière, qu'elle conclue des partenariats, ce qui peut engendrer la divulgation de vos données. Nous vous informerons bien évidemment selon les devoirs que nous impose la législation actuelle dans une telle situation. Il est finalement possible que vos données soient communiquées à des organisations professionnelles ou sectorielles, à des associations ou d'autres organes, ce dans le respect de vos droits. </p>
      <p>Ces destinataires peuvent à leur tour solliciter des tiers qui auraient alors accès à vos données. S'il nous est loisible de limiter certains traitements (comme par exemple celui réalisé par un prestataire informatique), cela n'est pas le cas de tous (par exemple : banques, autorités, etc.). Des tiers sont également autorisés à collecter vos données dans le cadre de l'exploitation de nos services digitaux. Il peut s'agir de prestataires informatiques ou de marketing, etc. Dans une telle hypothèse, ces tiers sont responsables du traitement de données. </p>
      <div class="h4 mt-4">6.	Communication de vos données à l'étranger :</div>
      <p>Ainsi que cela vous a été indiqué, nous stockons des données principalement en Suisse et au sein de l'UE. Dans des cas exceptionnels, nous pouvons faire appel à des sous-traitants (ou nos prestataires peuvent faire appel à des sous-traitants) dans d'autres pays du monde. Nous privilégions des prestataires se trouvant dans des pays disposant d'un niveau de protection des données adéquat, selon la liste établie par le Préposé fédéral à la protection des données : <a href="www.fedlex.admin.ch/eli/cc/2022/568/fr#annex_1" target="_blank">https://www.fedlex.admin.ch/eli/cc/2022/568/fr#annex_1</a></p>
      <p>Si le destinataire de la communication de données ne se trouve pas dans un pays disposant d'un niveau de protection des données adéquat, nous assurons la protection de vos données d'une autre manière, notamment par des garanties contractuelles :</p>
      <ul class="points">
        <li><u>Clauses de protection des données dans un contrat spécifique</u> : la personne responsable du transfert et son cocontractant incluent dans leur contrat des clauses spécifiques de protection des données qui garantissent un niveau de protection adéquat. Avant le transfert à l'étranger, le Préposé fédéral à la protection des données doit être informé de ces clauses. Malgré la notification au Préposé, la charge de la preuve concernant la mise en œuvre de toutes les mesures nécessaires à la protection des données demeure du ressort du responsable du transfert. Contrairement aux clauses types de protection des données, les clauses de protection des données figurant dans un contrat s'appliquent exclusivement à la communication prévue par ledit contrat.</li>
        <li><u>Clauses types de protection des données</u> : les clauses types de protection des données (Standard Contractual Clauses, SCC) peuvent être élaborées par des particuliers, des milieux intéressés ou des organes fédéraux. De telles clauses doivent être approuvées préalablement par le Préposé fédéral à la protection des données. À moins que le transfert ne se fonde sur une autre base légale, toute communication de données à l'étranger est interdite tant que le Préposé fédéral n'a pas rendu sa décision. Le Préposé fédéral rend sa décision dans un délai de 90 jours (OPDo). Les clauses types de protection des données peuvent également être établies ou reconnues par le Préposé fédéral lui-même. L'utilisation de ces clauses n'est alors pas soumise à une notification au Préposé fédéral.</li>
      </ul>
      <p>Vous l'aurez compris, l'utilisation de telles garanties contractuelles est soumise à des contraintes légales très fortes qui vous garantissent le respect de vos droits fondamentaux. Le Préposé fédéral est également nanti de ce type de communications dans les cas où la loi le prévoit. </p>
      <p>Sachez finalement qu'il existe des exceptions, tel que l'existence d'une procédure judiciaire à l'étranger, l'existence d'un intérêt public prépondérant, ou encore les cas où l'exécution du contrat est dans votre intérêt, soit lorsque vous avez consenti à un tel traitement, ou dans des cas de force majeure (la divulgation est nécessaire pour protéger votre vie, votre intégrité physique ou celle de tiers) ou finalement lorsque vous avez rendu ces données publiques et que vous n'avez pas refusé le traitement. </p>
      <div class="h4 mt-4">7.	Quels sont les droits qui sont garantis par la législation applicable ?</div>
      <p>La loi vous accorde un certain nombre de droits en relation avec le traitement de vos données. Il vous est ainsi loisible de solliciter des informations relativement au traitement, de faire corriger des données inexactes, de requérir à certaines conditions la suppression de ces données, de vous opposer à leur traitement, d'en obtenir la portabilité, etc. </p>
      <p>Si vous souhaitez obtenir de plus amples informations, vous pouvez consulter le site du Préposé fédéral à la protection des données : <a href="https://www.edoeb.admin.ch/edoeb/fr/home.html" target="_blank">www.edoeb.admin.ch/edoeb/fr/home.html</a></p>
      <p>Pour exercer ces droits, nous vous prions de vous adresser à notre Conseiller à la protection des données, prénommé. </p>
      <div class="h4 mt-4">8.	Utilisation des cookies, des technologies apparentées et des plug-ins de réseaux sociaux</div>
      <p>La société LTY SA exploite un site internet, une application et elle est présente sur les réseaux sociaux. Lorsque vous interagissez avec nos différents services, des données techniques sont évidemment générées et stockées dans des fichiers journaux. Nous utilisons également des cookies ou des techniques apparentées (par exemple : pixel tag, empreintes digitales, etc.) pour vous reconnaître, respectivement analyser votre comportement et déterminer vos préférences.</p>
      <p>Un cookie est un petit fichier stocké par un serveur dans le terminal (ordinateur, téléphone, etc.) d'un utilisateur et associé à un domaine web (c'est à dire dans la majorité des cas à l'ensemble des pages d'un même site web).  Ce fichier est automatiquement renvoyé lors de contacts ultérieurs avec le même domaine.</p>
      <p>Il existe plusieurs possibilités pour vous protéger de cette collecte. La première consiste à configurer votre navigateur pour qu'il rejette ou supprime automatiquement les cookies. Vous pouvez également supprimer manuellement au cas par cas les cookies. Voici une page qui vous exposera en pratique la manière d'agir : <a href="https://www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur" target="_blank">www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur</a> </p>
      <p>Sur le principe, nous ne collectons pas de données techniques ni de cookies qui contiennent des données personnelles. Il se peut toutefois que les données personnelles que nous ou des fournisseurs tiers stockons à votre sujet puissent être corrélées à des données techniques ou à des informations stockées dans des cookies et obtenues à partir de ces derniers. Dans ce cas, les données peuvent être liées à votre personne. </p>
      <p>Dans le cadre de nos activités en ligne, nous utilisons des plug-ins sociaux. Les plugins sociaux sont des applications liées aux différents médias ou réseaux sociaux (Facebook, Twitter, Youtube, Viadeo, etc..) qui peuvent être placées sur un site tiers par un simple copier / coller de code fourni par la plateforme sociale. Ils peuvent permettre notamment de proposer la recommandation de contenus, d'utiliser des données de profils pour des commentaires, l'enregistrement ou la complétion de formulaires ou d'afficher des contenus en provenance des sites sociaux. Ces plug-ins informent donc des fournisseurs tiers du fait que vous avez utilisé nos services et ils peuvent leur transmettre les cookies précédemment placés sur votre navigateur internet. Il ne nous est pas possible de vous expliciter dans le détail la manière dont ces tiers utilisent vos données personnelles ainsi collectées. Nous vous invitons à consulter leurs déclarations de confidentialité respectives.</p>
      <p>Nous utilisons également des outils tiers (qui peuvent faire usage de cookies), notamment pour améliorer les fonctionnalités de nos sites, le contenu de nos produits et/ou services, créer différentes statistiques et diffuser notre publicité. </p>
      <p>Voici la liste des prestataires et partenaires publicitaires auxquels nous faisons appel :</p>
      <ul class="points">
        <li>Fournisseur <strong>Google Analytics</strong><br>
          Google Irlande Ltd. <br>
          <a href="https://support.google.com/analytics/answer/6004245" target="_blank">Déclaration de confidentialité</a></li>
        <li>Fournisseur <strong>Google Ads</strong><br>
          Google Irlande Ltd. <br>
          <a href="https://policies.google.com/privacy" target="_blank">Déclaration de confidentialité</a></li>
      </ul>
      <p>Ces fournisseurs se trouvent en dehors de la Suisse. Nous respectons lors de nos communications avec eux les règles légales applicable (cf. § 6). </p>
      <div class="h4 mt-4">9.	Traitement des données personnelles sur les réseaux sociaux</div>
      <p>Ainsi que cela vous a été exposé précédemment, nous utilisons les réseaux sociaux. Dans ce contexte, plusieurs interactions interviennent. Nous recevons différentes données de votre part (par exemple lorsque vous émettez des commentaires, etc.), mais également des différentes sociétés qui exploitent ces réseaux sociaux (des statistiques notamment). Quant aux exploitants de ces plateformes, ils peuvent procéder à une analyse de votre utilisation et corréler ces données avec celles qu'ils possèdent déjà à votre sujet. Ils poursuivent ainsi des finalités différentes des nôtres tout en étant responsables de ce ou ces traitements. Nous vous invitons à consulter les déclarations de confidentialité de ces réseaux sociaux. </p>
      <p>Pour de plus amples informations à ce sujet, vous pouvez consulter cette page : <a href="https://www.cybermalveillance.gouv.fr/tous-nos-contenus/bonnes-pratiques/reseaux-sociaux#:~:text=Escroquerie%2C%20usurpation%20d%27identité%2C,sécurité%20sur%20les%20réseaux%20sociaux" target="_blank">www.cybermalveillance.gouv.fr/tous-nos-contenus/bonnes-pratiques/reseaux-sociaux#:~:text=Escroquerie%2C%20usurpation%20d%27identité%2C,sécurité%20sur%20les%20réseaux%20sociaux</a>. Dès lors qu'il s'agit d'un site français, les règles légales applicables ne sont pas pertinentes. Seuls les conseils techniques doivent retenir votre attention. </p>
      <p>Nous utilisons actuellement les plateformes suivantes :</p>
      <ul class="points">
        <li><strong>Facebook</strong> <a href="https://www.facebook.com/privacy/policy" target="_blank" class="ms-1">Déclaration de confidentialité</a></li>
        <li><strong>Instagram</strong> <a href="https://privacycenter.instagram.com/policy" target="_blank" class="ms-1">Déclaration de confidentialité</a></li>
        <li><strong>Youtube</strong> <a href="https://policies.google.com/privacy?hl=fr" target="_blank" class="ms-1">Déclaration de confidentialité</a></li>
        <li><strong>LinkedIn</strong> <a href="https://fr.linkedin.com/legal/privacy-policy" target="_blank" class="ms-1">Déclaration de confidentialité</a></li>
        <li><strong>Twitter</strong> <a href="https://twitter.com/fr/privacy" target="_blank" class="ms-1">Déclaration de confidentialité</a></li>
        <li><strong>TikTok</strong> <a href="https://www.tiktok.com/legal/page/eea/privacy-policy/fr" target="_blank" class="ms-1">Déclaration de confidentialité</a></li>
      </ul>
      <p>Il ne nous appartient pas de vérifier les contenus externes avant ou après leur publication sur nos différentes, sauf cas particulier. Nous opérons une modération des contenus qui peut aboutir à la suppression pure et simple de contenus qui violent les règles légales ou la charte de ces plateformes. </p>
      <p>Ces prestataires se trouvant tous à l'étranger, nous vous invitons à consulter le chiffre 6 de la présente déclaration. </p>
      <div class="h4 mt-4">10.	Application éventuelle du RGPD</div>
      <p>Nous sommes d'avis que nous ne sommes pas soumis à l'application du règlement général de la protection des données de l'Union européenne (RGPD). Si par extraordinaire le RGPD devait tout de même trouver application, voici les buts que nous poursuivons à l'aune des dispositions topiques. </p>
      <p>Nous traitons vos données :</p>
      <ul class="points">
        <li>Pour préparer la conclusion de contrats, assurer leur gestion et leur mise en œuvre (art. 6 al. 1 let. b RGPD) ;</li>
        <li>-	Pour la sauvegarde de nos intérêts légitimes ou ceux de tiers, notamment pour la fourniture des services que vous nous demandez, pour communiquer avec vous ou avec des tiers, de même que pour améliorer nos produits et/ ou services, à des fins de sécurité et de gestion des risques, d'administration, ainsi que pour la sauvegarde d'autres intérêts légitimes, etc. (art. 6 al. 1 let. f RGPD) ;</li>
        <li>-	Lorsque cela est prescrit ou autorisé par la loi conformément au droit de l'UE ou d'un État membre ;</li>
        <li>-	Lorsque vous avez consenti séparément et explicitement au traitement de vos données, par exemple en sollicitant nos services numériques (art. 6 al. 1 let. a et art. 9 al. 2 let. a RGPD). </li>
      </ul>
      <p>Pour le surplus, nous vous renvoyons au texte du RGPD disponible à cette adresse : <a href="https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX%3A32016R0679" target="_blank">www.eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX%3A32016R0679</a>.</p>
      <p>Voici un bref résumé de vos droits et de la manière de les exercer :</p>
      <ul class="points">
        <li><strong>Information</strong> - Ce droit est exercé par le biais du présent document. Si parmi les données traitées figurent les données de votre personnel, vous vous portez fort de leur faire prendre connaissance de ce document.</li>
        <li><strong>Accès et rectification </strong> - Vous disposez du droit d'accéder à vos données et de les faire rectifier le cas échéant.</li>
        <li><strong>Opposition</strong> - Vous pouvez vous opposer au traitement de vos données que nous faisons sur la base de notre intérêt légitime.</li>
        <li><strong>Retirer votre consentement</strong> - Lorsque les données sont traitées en vertu de votre consentement, vous pouvez à tout moment revenir sur cette décision, sans remettre en cause le traitement passé.</li>
        <li><strong>Effacement</strong> - Vous pouvez obtenir l'effacement de vos données ou la limitation du traitement dans les conditions prévues aux articles 17 et 18 du Règlement général sur la protection des données.</li>
        <li><strong>Portabilité </strong> - Les données que vous nous avez fournies peuvent vous être communiquées ou transmises à un confrère sous format électronique.</li>
      </ul>
      <p>En cas d'interrogations, n'hésitez pas à contacter notre Conseiller à la protection des données. Si besoin était notre représentant au sein de l'UE au sens de l'article 27 RGPD est Lexing - Belgium, SCRL, Boulevard d'Avroy 280, 4000 Liège. </p>
      <div class="h4 mt-4">11.	Conservation de vos données</div>
      <p>Les données que vous nous confiez sont conservées 10 ans après l'achèvement de notre relation contractuelle. En cas de préjudice corporel notamment (cf. article 128a CO), le délai de conservation peut être porté à 20 ans. L'hypothèse d'une procédure judiciaire de plus longue durée est réservée.</p>
      <div class="h4 mt-4">12.	Modification de la présente déclaration de protection des données</div>
      <p>La présente déclaration peut, à tout moment, pour diverses raisons, faire l'objet de corrections, d'ajouts ou de modifications. La version la plus actuelle peut être consultée en permanence sur notre site Internet. Nous vous invitons à la consulter régulièrement.</p>
    </ng-scrollbar>
  </div>

</ng-template>
