<div class="right-block">
	<section class="dashboard-content">
		<div class="dashboard-card">
			<div class="update-profile-container">
				<div class="dashboard-title-block mb-4">
					<div class="h3" i18n>Account Setting</div>
				</div>
				<div class="update-prof-card d-flex">
					<aside class="update-prof-sidebar">
						<div class="update-prof-head text-center">
							<form enctype="multipart/form-data">
								<div class="profile-photo">
									<figure> <img *ngIf="_userdetailuser.profilePic!='' && _userdetailuser.profilePic!=null" src="{{profilePicurl}}" alt=""> <img *ngIf="_userdetailuser.profilePic=='' || _userdetailuser.profilePic==null" src="assets/img/profile-user.png" alt=""> <span title="Upload Profile Picture">
                    <input type="file" accept=".png, .jpg, .jpeg, PNG" #fileInput (change)="uploadProfileFile($event)">
                    <i class="bi bi-camera-fill"></i>
                  </span> </figure>
								</div>
							</form>
							<div class="h6"> {{_userdetailuser.firstName}} {{_userdetailuser.lastName}} </div>
						</div>
						<ul class="nav flex-column" id="profileTab" role="profileTabList">
							<li class="nav-item" role="presentation">
								<button class="nav-link active" id="account-tab" data-bs-toggle="tab" data-bs-target="#account" type="button" role="tab" aria-controls="account" aria-selected="true"><span><i class="bi bi-pencil-fill"></i></span><ng-container i18n>Account</ng-container></button>
							</li>
							<li class="nav-item" role="presentation">
								<button class="nav-link" id="security-tab" data-bs-toggle="tab" data-bs-target="#security" type="button" role="tab" aria-controls="security" aria-selected="false"><span><i class="bi bi-shield-shaded"></i></span><ng-container i18n>Security</ng-container></button>
							</li>
							<!--<li class="nav-item" role="presentation">
								<button class="nav-link" id="payment-history-tab" data-bs-toggle="tab" data-bs-target="#payment-history" type="button" role="tab" aria-controls="payment-history" aria-selected="false"><span><i class="bi bi-cash-stack"></i></span>Payment History</button>
							</li>-->
						</ul>
					</aside>
					<div class="update-prof-content">
						<div class="tab-content" id="myTabContent">
							<div class="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">
								<form [formGroup]="profileFormGroup" (ngSubmit)="UpdateProfileDetail()" #profilefss="ngForm" enctype="multipart/form-data">
								<!-- <form [formGroup]="profileFormGroup" (ngSubmit)="UpdateProfileDetail()" enctype="multipart/form-data"> -->
									<div class="row row-cols-md-1 row-cols-lg-2 mb-3 mb-md-5">
										<div class="col">
											<label class="mb-1" i18n>User Type</label>
											<input type="text" class="form-control" formControlName="type"> </div>
										<div class="col" *ngIf="_userdetailuser.type==='Company'">
											<label class="mb-1" i18n>Company Name <sup>*</sup></label>
											<input type="text" class="form-control" formControlName="companyName" >
											<div *ngIf="profilef['companyName'].invalid && (profilef['companyName'].dirty || profilef['companyName'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['companyName'].errors?.['required']" i18n> Company name is required. </div>
											</div>
										</div>
									</div>
									<div class="row row-cols-md-1 row-cols-lg-2 mb-3 mb-md-5" *ngIf="_userdetailuser.type==='Company'">
										<div class="col">
											<label class="mb-1" i18n>Company Website <sup>*</sup></label>
											<input  type="text" class="form-control" formControlName="website" >
											<div *ngIf=" profilef['website'].invalid && (profilef['website'].dirty || profilef['website'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf=" profilef['website'].errors?.['required']" i18n> Company Website is required. </div>
												<div *ngIf=" profilef['website'].errors?.['pattern']" i18n> Company Website must be valid Website URL. </div>
											</div>
										</div>
										<div class="col">
											<label class="mb-1" i18n>Number of Employee </label>
											<input type="number" class="form-control" formControlName="noOfEmployee" >
											<div *ngIf="  profilef['noOfEmployee'].invalid && (profilef['noOfEmployee'].dirty || profilef['noOfEmployee'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf=" profilef['noOfEmployee'].errors?.['required']" i18n> Number of Employee is required. </div>
											</div>
										</div>
									</div>

									<div class="row row-cols-md-1 row-cols-lg-2 mb-3 mb-md-5" *ngIf="_userdetailuser.type==='Company'">
										<div class="col">
											<label class="mb-1" i18n>Company ID </label>
											<input type="text" formControlName="companyId" class="form-control"  >
											<div *ngIf=" profilef['companyId'].invalid && (profilef['companyId'].dirty || profilef['companyId'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf=" profilef['companyId'].errors?.['required']" i18n> Company ID is required. </div>
											</div>
										</div>
										<div class="col">
											<label class="mb-1" i18n>Legal Entity </label>
											<input type="text" formControlName="legalEntity" class="form-control"  >
											<div *ngIf="profilef['legalEntity'].invalid && (profilef['legalEntity'].dirty || profilef['legalEntity'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['legalEntity'].errors?.['required']" i18n> Legal Entity is required. </div>
											</div>
										</div>
									</div>

									<div class="row row-cols-md-1 row-cols-lg-2 mb-3 mb-md-5">
										<div class="col">
											<label class="mb-1" i18n>First Name <sup>*</sup></label>
											<input  type="text" class="form-control" formControlName="firstName"  >
											<!--<div *ngIf=" profilef['firstName'].invalid && (profilef['firstName'].dirty || profilef['firstName'].touched || profileForm.submitted)" class="invalid">-->
											<div *ngIf=" profilef['firstName'].invalid && (profilef['firstName'].dirty || profilef['firstName'].touched || profilefss.submitted )" class="invalid">
												<div *ngIf="profilef['firstName'].errors?.['required']" i18n> First name is required. </div>
											</div>
										</div>

										<div class="col">
											<label class="mb-1" i18n>Last Name <sup>*</sup></label>
											<input type="text" class="form-control" formControlName="lastName">
											<div *ngIf="profilef['lastName'].invalid && (profilef['lastName'].dirty || profilef['lastName'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['lastName'].errors?.['required']" i18n> First name is required. </div>
											</div>
										</div>
									</div>

									<div class="row row-cols-md-1 row-cols-lg-2 mb-3 mb-md-5">
										<div class="col form-group">
											<label class="mb-1 d-flex">
												<div class="d-flex w-100"> <span i18n>Email</span> <a class="ms-auto" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#verifyEmail"><small><i class="bi bi-patch-check"></i><ng-container i18n>Update Email</ng-container></small></a> </div>
											</label>
											<input formControlName="useremail" type="email" class="form-control" readonly="true"> 
										</div>
										<div class="col form-group">
											<label class="mb-1 d-flex">
												<div class="d-flex w-100"> <span i18n>Contact No.</span> <a class="ms-auto" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#verifyContactNo"><small><i class="bi bi-patch-check"></i><ng-container i18n>Update Contact No.</ng-container> </small></a> </div>
											</label>

											<!-- <input type="text" class="form-control" formControlName="countryCodeShow" readonly="true"  > </div>

											<input type="text" class="form-control" formControlName="userMobile" readonly="true"  > </div> -->

											<div class="row gx-1">
												<div class="col-md-3">
													<input type="text" class="form-control" formControlName="countryCodeShow" readonly="true"  >
												</div>
												<div class="col-md-9">
												  <input type="text" class="form-control" formControlName="userMobile" readonly="true" />
												</div>
											</div>
										</div>
									</div>

									<div class="row row-cols-md-1 row-cols-lg-1 mb-3 mb-md-5">
										<div class="col form-group">
											<label class="mb-1" i18n>Date of Birth <sup>*</sup></label>
											<input type="date"  class="form-control" formControlName="userDateofBirth" max="{{maxDate}}"  >
											<div *ngIf="profilef['userDateofBirth'].invalid && (profilef['userDateofBirth'].dirty || profilef['userDateofBirth'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['userDateofBirth'].errors?.['required']" i18n> Date of Birth is required. </div>
												<div *ngIf="profilef['userDateofBirth'].errors?.['pattern']" i18n> Date of Birth must be a valid date in the format MM/DD/YYYY. </div>
												<div *ngIf="profilef['userDateofBirth'].errors?.['invalidAge']" i18n> Your age must be 16 years or more to register. </div>
											</div>
										</div>
									</div>

									<div class="row row-cols-md-1 row-cols-lg-2 mb-3 mb-md-5">
										<div class="col form-group">
											<label class="mb-1" i18n>Gender <sup>*</sup></label>
											<select formControlName="userGender" class="form-control">
												<option value="" i18n>Select Gender</option>
												<option value="Male" i18n>Male</option>
												<option value="Female" i18n>Female</option>
												<!-- <option value="Neutral" i18n>Neutral</option> -->
											</select>
											<div *ngIf="profilef['userGender'].invalid && (profilef['userGender'].dirty || profilef['userGender'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['userGender'].errors?.['required']" i18n> Gender is required. </div>
											</div>
										</div>
										<div class="col form-group">
											<label class="mb-1" i18n>Marital Status <sup>*</sup></label>
											<select  class="form-control" formControlName="userMarital">
												<option value="" i18n>Select Marital Status</option>
												<option value="Single" i18n>Single</option>
												<option value="Married" i18n>Married</option>
											</select>
											<div *ngIf="profilef['userMarital'].invalid && (profilef['userMarital'].dirty || profilef['userMarital'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['userMarital'].errors?.['required']" i18n> Marital Status is required. </div>
											</div>
										</div>
									</div>

									<!-- <input formControlName="address" class="form-control" ngx-google-places-autocomplete  #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/> -->

									<div class="row row-cols-md-1 row-cols-lg-1 mb-3 mb-md-5">
										<div class="col form-group">
											<label class="mb-1" i18n>Address <sup>*</sup></label>
											<input formControlName="userAddress" class="form-control" placeholder="" ngx-google-places-autocomplete  #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>
											<!-- <input formControlName="userAddress" type="text" class="form-control" >
											<div *ngIf="profilef['userAddress'].invalid && (profilef['userAddress'].dirty || profilef['userAddress'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['userAddress'].errors?.['required']">Address is required. </div>
											</div> -->
										</div>
									</div>

									<!-- <div class="row row-cols-md-1 row-cols-lg-2 mb-3 mb-md-5">
										<div class="col form-group">
											<label class="mb-1">Country <sup>*</sup></label>
											<select formControlName="userCountry" class="form-control" (change)="getStates('1')">
												<option value="">Select Country</option>
												<option value="{{country.name}}" *ngFor="let country of country_List; let i = index">{{country.name}}</option>
											</select>
											<div *ngIf="profilef['userCountry'].invalid && (profilef['userCountry'].dirty || profilef['userCountry'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['userCountry'].errors?.['required']"> Country is required. </div>
											</div>
										</div>
										<div class="col form-group">
											<label class="mb-1">State <sup>*</sup></label>
											<select formControlName="userState" class="form-control" (change)="getCities('1')" >
												<option value="">Select state</option>
												<option value="{{state.name}}" *ngFor="let state of state_List; let i = index">{{state.name}}</option>
											</select>
											<div *ngIf="profilef['userState'].invalid && (profilef['userState'].dirty || profilef['userState'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['userState'].errors?.['required']"> State is required. </div>
											</div>
										</div>
									</div>

									<div class="row row-cols-md-1 row-cols-lg-2 mb-3 mb-md-5">
										<div class="col form-group">
											<label class="mb-1">City <sup>*</sup></label>
											<select formControlName="userCity" class="form-control" >
												<option value="">Select City</option>
												<option value="{{city.name}}" *ngFor="let city of city_List; let i = index">{{city.name}}</option>
											</select>
											<div *ngIf="profilef['userCity'].invalid && (profilef['userCity'].dirty || profilef['userCity'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['userCity'].errors?.['required']"> City is required. </div>
											</div>
										</div>
										<div class="col form-group">
											<label class="mb-1">Postal Code <sup>*</sup></label>
											<input type="text" class="form-control" formControlName="userPostalCode"   >
											<div *ngIf="profilef['userPostalCode'].invalid && (profilef['userPostalCode'].dirty || profilef['userPostalCode'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['userPostalCode'].errors?.['required']"> Postal Code is required. </div>
												<div *ngIf="profilef['userPostalCode'].errors?.['maxlength']"> Postal Code should be max 6 characters long. </div>
											</div>
										</div>
									</div> -->

									<div class="row row-cols-md-1 row-cols-lg-2 mb-3 mb-md-5">
										<div class="col form-group">
											<label class="mb-1" i18n>Country <sup>*</sup></label>
											<input type="text" class="form-control" formControlName="userCountry"   >
											<div *ngIf="profilef['userCountry'].invalid && (profilef['userCountry'].dirty || profilef['userCountry'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['userCountry'].errors?.['required']" i18n> Country is required. </div>
											</div>
										</div>
										<div class="col form-group">
											<label class="mb-1">State <sup>*</sup></label>
											<input type="text" class="form-control" formControlName="userState"   >
											<div *ngIf="profilef['userState'].invalid && (profilef['userState'].dirty || profilef['userState'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['userState'].errors?.['required']" i18n> State is required. </div>
											</div>
										</div>
									</div>

									<div class="row row-cols-md-1 row-cols-lg-2 mb-3 mb-md-5">
										<div class="col form-group">
											<label class="mb-1" i18n>City <sup>*</sup></label>
											<input type="text" class="form-control" formControlName="userCity"   >
											<div *ngIf="profilef['userCity'].invalid && (profilef['userCity'].dirty || profilef['userCity'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['userCity'].errors?.['required']" i18n> City is required. </div>
											</div>
										</div>
										<div class="col form-group">
											<label class="mb-1" i18n>Postal Code <sup>*</sup></label>
											<input type="text" class="form-control" formControlName="userPostalCode"   >
											<div *ngIf="profilef['userPostalCode'].invalid && (profilef['userPostalCode'].dirty || profilef['userPostalCode'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['userPostalCode'].errors?.['required']" i18n> Postal Code is required. </div>
												<div *ngIf="profilef['userPostalCode'].errors?.['maxlength']" i18n> Postal Code should be max 6 characters long. </div>
											</div>
										</div>
									</div>

									<div class="row row-cols-md-1 row-cols-lg-2 mb-3 mb-md-5">
										<div class="col form-group">
											<label class="mb-1" i18n>In which insurance company you already have contracts ?<sup></sup></label> 
											<mat-form-field>
												<mat-select  [formControl]="insurerCompanies"  multiple>
												  <mat-option *ngFor="let company of insuranceCompany_List" [value]="company.category">{{userlang=="en-US"?company.category:userlang=="fr-CA" ?company.categoryInFrench:""}}</mat-option><!--userlang=='en-US'?company.category:userlang=='fr-CA' ?company.categoryInFrench:''-->
												</mat-select>
											  </mat-form-field>
										</div>
										<div class="col form-group">
										</div>
									</div>


									<input type="hidden" formControlName="partnerId" class="form-control">
									<input type="hidden" formControlName="agentId" class="form-control">
									<div class="row row-cols-md-1 row-cols-lg-1 mb-3 mb-md-5">
										<div class="col form-group mb-4">
											<label class="mb-1" i18n>Upload Registration ID Front <sup>*</sup></label>
											<div class="file-type-two">
												<input formControlName="registrationId" type="hidden" class="form-control">
												<input type="file" id="imageUpload" accept=".png, .jpg, .jpeg, .docx, .pdf" #fileInput (change)="uploadFile($event)">
												<i class="bi bi-cloud-arrow-up-fill"></i>
												<span><ng-container i18n="@@dragdrop">Drag & Drop or </ng-container> <span i18n="@@browsefile">browse</span></span>
											</div>

											<div *ngIf="profilef['registrationId'].invalid && (profilef['registrationId'].dirty || profilef['registrationId'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['registrationId'].errors?.['required']" i18n> Registration ID Front is required. </div>
											  </div>

											<div class="img_preview">
												<div class="bx" *ngIf="regIdType=='png' || regIdType=='jpg' || regIdType=='jpeg' || regIdType=='PNG' || regIdType=='JPG' || regIdType=='JPEG'">
													<figure>
														<a href="{{regIdFront}}" target="_blank"><img src="{{regIdFront}}" alt="Registration img" class="img-fluid"></a>
													</figure>
												</div>
												<div class="bx" *ngIf="regIdType=='pdf' || regIdType=='docx' || regIdType=='PDF' || regIdType=='DOCX'">
													<figure><a href="{{regIdFront}}" target="_blank"><i class="bi bi-file-pdf text-danger"></i></a></figure> <a href="javascript:void(0);" class="clos"><i class="bi bi-x"></i></a> </div>
											</div>
										</div>
										<div class="col form-group">
											<label class="mb-1" i18n>Upload Registration ID Back <sup>*</sup></label>
											<div class="file-type-two">
												<input formControlName="registrationIdBack" type="hidden" class="form-control">
												<input type="file" id="imageUpload" accept=".png, .jpg, .jpeg, .docx, .pdf" (change)="uploadFile1($event)" class="form-control">
												<i class="bi bi-cloud-arrow-up-fill"></i>
												<span><ng-container i18n="@@dragdrop">Drag & Drop or </ng-container> <span i18n="@@browsefile">browse</span></span>
											</div>
											

											<div *ngIf="profilef['registrationIdBack'].invalid && (profilef['registrationIdBack'].dirty || profilef['registrationIdBack'].touched || profilefss.submitted)" class="invalid">
												<div *ngIf="profilef['registrationIdBack'].errors?.['required']" i18n> Registration ID Back is required. </div>
											  </div>
											  
											<div class="img_preview">
												<div class="bx" *ngIf="regIdTypeBack=='png' || regIdTypeBack=='jpg' || regIdTypeBack=='jpeg' || regIdTypeBack=='PNG' || regIdTypeBack=='JPG' || regIdTypeBack=='JPEG'">
													<figure>
														<a href="{{regIdBack}}" target="_blank"><img src="{{regIdBack}}" alt="Registration img" class="img-fluid"></a>
													</figure>
												</div>
												<div class="bx" *ngIf="regIdTypeBack=='pdf' || regIdTypeBack=='docx' || regIdTypeBack=='PDF' || regIdTypeBack=='DOCX'">
													<figure><a href="{{regIdBack}}" target="_blank"><i class="bi bi-file-pdf text-danger"></i></a></figure> <a href="javascript:void(0);" class="clos"><i class="bi bi-x"></i></a> </div>
											</div>
										</div>
									</div>
									<div class="row row-cols-md-1 row-cols-lg-2 mb-3 mb-md-5">
										<div class="col">
											<button type="submit" class="btn btn-primary w-100" i18n>Update</button>
										</div>
									</div>
								</form>
							</div>


							<div class="tab-pane fade" id="security" role="tabpanel" aria-labelledby="security-tab">
								<form [formGroup]="securityFormGroup" (ngSubmit)="UpdateSecurityDetail()">
									<div class="row row-cols-md-1 row-cols-lg-1 mb-3 mb-md-5">
										<div class="col form-group">
											<label class="mb-1" i18n>Old password</label>
											<div class="password-group">
												<input formControlName="oldPassword" class="form-control" [type]="showPasswordold ? 'text' : 'password'" name="" id="" placeholder=" "> <span class="show-hide-pass" (click)="showPasswordold = !showPasswordold">
												<i class="mdi" [ngClass]="{'bi-eye-fill': !showPasswordold, 'bi-eye-slash-fill': showPasswordold}"></i>
												</span> </div>
											<div *ngIf="firstf['oldPassword'].invalid && (firstf['oldPassword'].dirty || firstf['oldPassword'].touched)" class="invalid">
												<div *ngIf="firstf['oldPassword'].errors?.['required']" i18n> Old Password is required. </div>
											</div>
										</div>
									</div>
									<div class="row row-cols-md-1 row-cols-lg-1 mb-3 mb-md-5">
										<div class="col form-group">
											<label class="mb-1" i18n>New password</label>
											<div class="password-group">
												<input formControlName="newPassword" class="form-control" [type]="showPassword ? 'text' : 'password'" name="" id="" placeholder=" "> <span class="show-hide-pass" (click)="showPassword = !showPassword">
											<i class="mdi" [ngClass]="{'bi-eye-fill': !showPassword, 'bi-eye-slash-fill': showPassword}"></i>
											</span> </div>
											<div *ngIf="firstf['newPassword'].invalid && (firstf['newPassword'].dirty || firstf['newPassword'].touched)" class="invalid">
												<div *ngIf="firstf['newPassword'].errors?.['required']" i18n> New Password is required. </div>
												<div *ngIf="firstf['newPassword'].errors?.['minlength']"> Password should be least 8 characters long. </div>
												<div *ngIf="firstf['newPassword'].errors?.['strong']" i18n> Please enter atleast one Capital letter, one small letter , one number, one special charactor and min length 8. </div>
											</div>
										</div>
									</div>
									<div class="row row-cols-md-1 row-cols-lg-1 mb-3 mb-md-5">
										<div class="col form-group">
											<label class="mb-1" i18n>Confirm password</label>
											<div class="password-group">
												<input formControlName="newPasswordC" class="form-control" [type]="showPasswordc ? 'text' : 'password'" name="" id="" placeholder=" "> <span class="show-hide-pass" (click)="showPasswordc = !showPasswordc">
											<i class="mdi" [ngClass]="{'bi-eye-fill': !showPasswordc, 'bi-eye-slash-fill': showPasswordc}"></i>
											</span> </div>
											<div *ngIf="firstf['newPasswordC'].invalid && (firstf['newPasswordC'].dirty || firstf['newPasswordC'].touched)" class="invalid">
												<div *ngIf="firstf['newPasswordC'].errors?.['required']" i18n> Confirm New Password is required. </div>
												<div *ngIf="firstf['newPasswordC'].errors?.['matching']" i18n> Confirm Password does not match </div>
											</div>
										</div>
									</div>
									<div class="row row-cols-md-1 row-cols-lg-2 mb-3 mb-md-5">
										<div class="col">
											<button type="submit" class="btn btn-primary w-100" i18n>Change Password</button>
										</div>
									</div>
								</form>
							</div>
							<div class="tab-pane fade" id="payment-history" role="tabpanel" aria-labelledby="payment-history-tab"> </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>

<!-- popup design -->
<div class="modal fade" id="verifyEmail" tabindex="-1" aria-labelledby="verifyEmailModalToggleLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header justify-content-center">
				<h5 class="modal-title text-capitalize" id="exampleModalLabel" i18n>Update your email</h5> </div>
			<div class="modal-body py-4">
				<form [formGroup]="EMailForm">
					<div class="form-group">
						<input formControlName="newEmail" class="form-control text-center" type="email"  >
						<div *ngIf="newemailf['newEmail'].invalid && (newemailf['newEmail'].dirty || newemailf['newEmail'].touched)" class="invalid">
							<div *ngIf="newemailf['newEmail'].errors?.['required']" i18n> Email is required. </div>
							<div *ngIf="newemailf['newEmail'].errors?.['email']" i18n> Please enter a valid email address. </div>
						</div>
					</div>
					<div class="form-group text-center"> </div>
				</form>
			</div>
			<div class="modal-footer justify-content-center">
				<button class="btn btn-primary me-3" (click)="UpdateEMailBtn()" i18n>Update</button>
				<button class="btn btn-transparent" data-bs-dismiss="modal" i18n>Cancel</button>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="updateEmailOtp" tabindex="-1" aria-labelledby="updateEmailOtpModalToggleLabel2" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header justify-content-center">
				<h5 class="modal-title text-capitalize" id="exampleModalLabel" i18n>OTP Verification Code</h5> </div>
			<form [formGroup]="OTPFormGroup">
				<div class="modal-body py-4">
					<div class="form-group">
						<ngx-otp-input class="justify-content-center" [config]="otpInputConfig" (otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)" placeholder="4444"> </ngx-otp-input>
					</div>
				</div>
				<div class="modal-footer justify-content-center">
					<button [disabled]="!checkCurrentLesson1" (click)="VerifyEMailotpBtn()" class="btn btn-primary me-3" i18n>Verify OTP</button>
					<button class="btn btn-transparent" data-bs-dismiss="modal" i18n>Close</button>
				</div>
			</form>
		</div>
	</div>
</div>

<div class="modal fade" id="verifyContactNo" tabindex="-1" aria-labelledby="verifyEmailModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header justify-content-center">
				<h5 class="modal-title text-capitalize" id="exampleModalLabel" i18n>Update your contact no</h5> </div>
			<div class="modal-body py-4">
				<form [formGroup]="MobileForm" #newmobilefss="ngForm">
					<!-- <div class="form-group">
						<label class="mb-2" for="">Contact No.</label>
						<input class="form-control text-center" formControlName="newMobile" >
						<div *ngIf="newmobilef['newMobile'].invalid && (newmobilef['newMobile'].dirty || newmobilef['newMobile'].touched)" class="invalid">
							<div *ngIf="newmobilef['newMobile'].errors?.['required']"> Contact No. is required. </div>
							<div *ngIf="newmobilef['newMobile'].errors?.['pattern']"> Please enter 10-13 digit Contact Number. </div>
						</div>
					</div> -->

					<!-- <div class="col form-group">
						<label for="">Mobile Number <sup>*</sup></label>
						<div class="row gx-1">
						  <div class="col-md-4">
							<select class="form-control" formControlName="countryCode" >
							  <option *ngFor="let country of country_List;" value="{{country.phonecode}}">{{country.sortname}} (+ {{country.phonecode}} )</option>
							</select>
							<div *ngIf="newmobilef['countryCode'].invalid && (newmobilef['countryCode'].dirty || newmobilef['countryCode'].touched || newmobilefss.submitted )" class="invalid">
							  <div *ngIf="newmobilef['countryCode'].errors?.['required']">
								Country Code is required.
							  </div>
							</div>
						  </div>
						  <div class="col-md-8">
							<input type="number" class="form-control" formControlName="newMobile" />
							<div *ngIf="newmobilef['newMobile'].invalid && (newmobilef['newMobile'].dirty || newmobilef['newMobile'].touched || newmobilefss.submitted)" class="invalid">
							  <div *ngIf="newmobilef['newMobile'].errors?.['required']">
								Mobile Number is required.
							  </div>
							  <div *ngIf="newmobilef['newMobile'].errors?.['pattern']">
								Mobile Number must be a valid 9-13 digit number.
							  </div>
							</div>
						  </div>
						</div>
					</div> -->

					<label class="mb-1" i18n>Mobile <sup>*</sup></label>
                            <ngx-intl-tel-input
                            [cssClass]="'custom'"
                            [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="false"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[
                              SearchCountryField.Iso2,
                              SearchCountryField.Name
                            ]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="iso"
                            [maxLength]="15"
                            [phoneValidation]="true"
                            [separateDialCode]="separateDialCode"
                            [numberFormat]="PhoneNumberFormat.National"
                            name="newMobile"
                            formControlName="newMobile"
                          >
                          </ngx-intl-tel-input>

						  <div *ngIf="newmobilef['newMobile'].invalid && (newmobilef['newMobile'].dirty || newmobilef['newMobile'].touched || newmobilefss.submitted)" class="invalid">
							<div *ngIf="newmobilef['newMobile'].errors?.['required'] " i18n>
							  Mobile Number is required.
							</div>
							<div *ngIf="newmobilef['newMobile'].errors?.['validatePhoneNumber']" i18n>
							  Please enter valid mobile number.
							</div>
						  </div>
						<!-- 
						  <mat-error *ngIf="newmobilefss.form.controls['newMobile']?.errors?.['required']">
							Required Field
							</mat-error>
							<mat-error *ngIf="newmobilefss.form.controls['newMobile']?.errors?.['validatePhoneNumber']"> 
								Invalid Number
						</mat-error> -->
				</form>
			</div>
			<div class="modal-footer justify-content-center">
				<button class="btn btn-primary me-3" (click)="UpdateMobileBtn()" i18n>Update</button>
				<button class="btn btn-transparent" data-bs-dismiss="modal" i18n>Cancel</button>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="updatephoneOtp" tabindex="-1" aria-labelledby="verifyEmailModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header justify-content-center">
				<h5 class="modal-title text-capitalize" id="exampleModalLabel" i18n>OTP Verification Code</h5> </div>
			<div class="modal-body py-4">
				<form action="">
					<div class="form-group">
						<ngx-otp-input class="justify-content-center" [config]="otpInputConfig" (otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)" placeholder="4444"> </ngx-otp-input>
					</div>
				</form>
			</div>
			<div class="modal-footer justify-content-center">
				<button class="btn btn-primary me-3" [disabled]="!checkCurrentLesson1" (click)="VerifyMobileotpBtn()" i18n>Update</button>
				<button class="btn btn-transparent" data-bs-dismiss="modal" i18n>Cancel</button>
			</div>
		</div>
	</div>
</div>