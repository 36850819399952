
<main class="user-dashboard">
  <div class="dashboard-container">
    <div class="left-block">
      <app-sidebar></app-sidebar>
    </div>
    <app-header ></app-header>
    <router-outlet></router-outlet>
  </div>
</main>
