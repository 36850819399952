// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  DATE_FORMAT:'dd.MM.yyyy',
  USERAPIENDPOINT:"https://identity.dev.ltytech.ch/api/ims/v1/",
  COMMUNICATORAPIURL:"https://communicator.dev.ltytech.ch/api/communicator/v1/",
  DOCUMENTAPIURL:"https://document-store.dev.ltytech.ch/api/document-store/v1/",
  INSURANCEAPIURL:"https://insurance-aggregator.dev.ltytech.ch/api/insurance/v1/",
  //FILEPATHPOINT:"http://44.203.193.10/backend/uploads/",
  CATALOGUE:"https://catalogue.dev.ltytech.ch/api/catalogue/v1/",
  COMMUNICATION:"https://communicator.dev.ltytech.ch/api/communicator/v1/communication/",

  firebaseConfig :{
      apiKey: "AIzaSyADkb1y-3LbJymHtDfRs7waUU4WU1LsJUc",
      authDomain: "ltypartner.firebaseapp.com",
      databaseURL: "https://ltypartner-default-rtdb.firebaseio.com",
      projectId: "ltypartner",
      storageBucket: "ltypartner.appspot.com",
      messagingSenderId: "359287358840",
      appId: "1:359287358840:web:a2f6f2b56c84211f9b348d",
      measurementId: "G-YF9MG7SK5G",
      vapidKey:"BIfMbFrAJ6Fkh7VJXItdftKXmoR5H-Zr61HalNDLfLhJbdiZ24Sd8OaI90JDhbEqCi_eTYgQm1o8UGM"
    },
    firebaseServerKey: "AAAAU6cyBXg:APA91bH5Iju59XwhNW_Rvu9h9KP_hhYTwbozoVPmW9OJF3JYOInZS-Z8W56eR6beN8ZQRfsYuplhj3087IcLz__dJhWNbi4PMvJlrNkh6vfmmRiJWuLQ6EhutA8muA5g5qF5KYut53Gl",
    firebaseFcmUrl: "https://fcm.googleapis.com/fcm/send"
};

export class AppConfig {
  public static get USERAPIENDPOINT(): string { return "https://identity.dev.ltytech.ch/api/ims/v1/"; }
  public static get COMMUNICATORAPIURL(): string { return "https://communicator.dev.ltytech.ch/api/communicator/v1/"; }
  public static get DOCUMENTAPIURL(): string { return "https://document-store.dev.ltytech.ch/api/document-store/v1/"; }
  public static get INSURANCEAPIURL(): string { return "https://insurance-aggregator.dev.ltytech.ch/api/insurance/v1/"; }
  public static get FILEPATHPOINT(): string { return "http://44.203.193.10/backend/uploads/"; }
public static get errorMessage(): string { return 'Some error occurred.'; }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
