
    <div class="right-block">
      <section class="dashboard-content">
        <div class="dashboard-card">
          <div class="dashboard-title-block mb-4">
            <div class="h3" i18n>Overview</div>
          </div>

          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-4 gy-4 gy-xl-0 dash-boxes">
            <div class="col">
              <a href="javascript:void(0)" routerLink="/user/general-offer">
                <div class="card bg-warning">
                  <div class="card-body">
                    <div class="card-icon added-doc-dash"></div>
                    <div class="card-footer">
                      <div class="count-number" i18n>{{Dashboarddata?.generalProposalCount}}</div>
                      <div class="card-title" i18n>General Offer</div>
                    </div>
                  </div>
                </div> 
              </a>
            </div>

            <div class="col">
              <a href="javascript:void(0)" routerLink="/user/personalised-offer">
                <div class="card bg-secondary">
                  <div class="card-body">
                    <div class="card-icon personalised-dash"></div>
                    <div class="card-footer">
                      <div class="count-number">{{Dashboarddata?.personalizedCount}}</div>
                      <div class="card-title" i18n="@@8182405134932712304">Current & completed offers</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col">
              <a href="javascript:void(0)" routerLink="/user/contracts">
                <div class="card bg-info">
                  <div class="card-body">
                    <div class="card-icon add-contract-dash"></div>
                    <div class="card-footer">
                      <div class="count-number">{{Dashboarddata?.contractCount}}</div>
                      <div class="card-title" i18n>Active policies</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col">
              <a href="javascript:void(0)" routerLink="/user/claim">
                <div class="card bg-primary">
                  <div class="card-body">
                    <div class="card-icon proposal-dash"></div>
                    <div class="card-footer">
                      <div class="count-number"></div>
                      <div class="card-title" i18n>Claim</div><!--Added Documents ,-->
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>


          <!-- start recent proposal -->
          <div class="dashboard-title-block mt-5 mb-4">
            <div class="h3" i18n>Recent proposals</div>
          </div>

          <div class="recent-proposal-block">
            <div class="recent-proposal-row" *ngFor="let lead of proposal_List; let i = index">
              <div class="row align-items-center">
                <div class="col-lg-2">
                  <div class="d-flex align-items-center">
                    <!--<div class="flex-shrink-0">
                      <div class="user-sm-img">
                        <img src="assets/img/user1.png" alt="user 1">
                      </div>
                    </div>-->
                    <div class="flex-grow-1 ms-3">
                      <div class="h5">{{lead.memberName}}</div>
                      <p>{{lngData[lead.relation+'_'+lnguageCode]}}</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="ins-time-dtl">
                    <div class="fs-6"><a href="javascript:void(0);" routerLink="/user/personalised-offer" >{{lngData[lead.insuranceType+'_'+lnguageCode]}}</a></div>
                    <div class="fs-6">{{lead.createdOn | date : DATE_FORMAT}}</div>
                  </div>
                </div>
                

                <div class="col-lg-6">
                  <div class="userstatusbar">
                    <div id="steps">
                      <div class="step" [ngClass]="{'active': lead.status==='PENDING' || lead.status==='ACTIVE' || lead.status==='ACCEPTED' || lead.status==='CLOSED', 'done': lead.status==='PENDING' || lead.status==='ACTIVE' || lead.status==='ACCEPTED' || lead.status==='CLOSED'}" i18n-data-desc="@@fffff1" data-desc="Offer requested">
                        <img src="assets/img/check.svg" alt="check" i18n-alt>
                      </div> 
                      <div class="step" [ngClass]="{'active': lead.status==='PENDING' || lead.status==='ACTIVE' || lead.status==='ACCEPTED' || lead.status==='CLOSED', 'done': lead.status==='ACTIVE' || lead.status==='ACCEPTED' || lead.status==='CLOSED'}"  i18n-data-desc="@@fffff2" data-desc="Offer created">
                        <img src="assets/img/check.svg" alt="check" i18n-alt>
                      </div>
                      <div class="step" [ngClass]="{'active': lead.status==='ACTIVE' || lead.status==='ACCEPTED' || lead.status==='CLOSED', 'done': lead.status==='ACCEPTED' || lead.status==='CLOSED'}" i18n-data-desc="@@fffff3" data-desc="Signed doc">
                        <img src="assets/img/check.svg" alt="check" i18n-alt>
                      </div>
                      <div class="step" [ngClass]="{'active': lead.status==='ACCEPTED' || lead.status==='CLOSED', 'done': lead.status==='POLICY_CREATED' || lead.status==='CLOSED'}" i18n-data-desc="@@fffff4" data-desc="Policy created">
                        <img src="assets/img/check.svg" alt="check" i18n-alt>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
