import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../../_services/user-auth.service';
//import { Observable } from 'rxjs';


export const AuthGuardGuard = () => {
  const router = inject(Router);
  const auth = inject(UserAuthService);
  checkUserLogin(router, auth);
};

function checkUserLogin(route:any, auth:UserAuthService){
 //console.log(route);
 if(!auth.isUserLoggedIn()){
  route.navigate(['/signin']);
 }
 return true;
}


