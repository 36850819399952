import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import {FormControl, Validators, FormGroup, FormBuilder, ValidationErrors, EmailValidator} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserAuthService } from 'src/app/_services/user-auth.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
declare var $: any;
import { CookieService } from 'ngx-cookie-service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-family-update-profile',
  templateUrl: './family-update-profile.component.html',
  styleUrls: ['./family-update-profile.component.css']
})


export class FamilyUpdateProfileComponent implements OnInit {
  maxDate = new Date().getFullYear()+'-'+String(new Date().getMonth()).padStart(2, '0')+'-'+new Date().getDate();
  __MemberList:any;
  memberType_arr:any=[];
  memberType_arrFltr:any=[];
  __MemberDelete:any;
  memberType:any="";
  member_List:any=[];
  memberSuccessMessage:any;
  result:any;
  country_List:any=[];
  _user: any;
  myProfile: any;
  selectedFiles: any;
  profilePic: File;
  _UploadPic: any;
  fileName: any;
  profile_image: string | ArrayBuffer;
  regIdType: any;
  regIdTypeBack: any;
  regIdFront:any="";
  regIdBack:any="";
  regIdFront1:any="";
  regIdBack1:any="";
  form_type:any="";

  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  SearchCountryField = SearchCountryField;
  separateDialCode = true;
  PhoneNumberFormat = PhoneNumberFormat;
  iso:any="ch";
  lnguageCode:any;
  lngData:any;
  languageCode: any;
  constructor(private globals:Globals, private dialog:MatDialog, private formBuilder: FormBuilder, private toastrService: ToastrService, private router: Router, private userAuthService:UserAuthService,private cookieService:CookieService){
    this.lnguageCode=this.cookieService.get('selectedCountryCode');  //en-US // fr-CA
    if(this.lnguageCode=="en-US"){
      this.memberType_arrFltr=['Self','Spouse','Children'];
      this.memberType_arr=['Spouse','Children'];
    }
    if(this.lnguageCode=="fr-CA"){
      this.memberType_arrFltr=['Pour moi','Spouse','Children'];
      this.memberType_arr=["Conjoint","Enfant"];
    } 

    this.lngData=this.globals._getLangData();
    this.languageCode=this.cookieService.get('selectedCountryCode').substr(0, 2); 
  }

  familyMemberForm: FormGroup = new FormGroup({
    userfirstname: new FormControl('', [Validators.required]),
    userlastname: new FormControl('', [Validators.required]),
    usermemberType: new FormControl('', [Validators.required]),
    userEmail: new FormControl('', [Validators.required, Validators.email]),
    usergender: new FormControl("Male", [Validators.required]),
    userdob: new FormControl('', [Validators.required,Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]),
    countryCode: new FormControl(""),
    usermobile: new FormControl("", [Validators.required]),
    registrationId: new FormControl("",[Validators.required]),
    registrationIdBack: new FormControl("",[Validators.required]),
    //useraddress: new FormControl('', [Validators.required])
  },{
    updateOn: "change",
  });

  familyMemberFormEdit = new FormGroup({
    familyMemberId: new FormControl('', [Validators.required]),
    userfirstname: new FormControl('', [Validators.required]),
    userlastname: new FormControl('', [Validators.required]),
    usermemberType: new FormControl('', [Validators.required]),
    userEmail: new FormControl('', [Validators.required, Validators.email]),
    usergender: new FormControl("Male", [Validators.required]),
    userdob: new FormControl('', [Validators.required,Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]),
    countryCode: new FormControl(""),
    usermobile: new FormControl("", [Validators.required]),
    registrationId: new FormControl("",[Validators.required]),
    registrationIdBack: new FormControl("",[Validators.required])
  },{
    updateOn: "change",
  });

  async ngOnInit():Promise<void>{
    window.scroll(0, 0);
    this.country_List=await this.getCountries();
    this.getCurrentUser();
    this.GetProfileDetail();
    this.getFamilymembers();
  }
  
  get fmemberError() { return this.familyMemberForm.controls; }
  get fmemberEError() { return this.familyMemberFormEdit.controls; }

  getFamilymembers(): void {
    this.__MemberList = this.userAuthService.getFamilymembers({
      relation:this.memberType
    }).subscribe({
     next: (x: any) => {
      this.member_List=x.data.familyMemberList;
     },
     error: (err: Error) => {
       let errRes: any;
       errRes = err;
       if(this.lnguageCode=="en-US"){
        this.toastrService.error(errRes.error.error.clientErrorMessage); 
      }
      if(this.lnguageCode=="fr-CA"){
        this.toastrService.error(errRes.error.error.clientErrorMessageInFrench); 
      } 
     },
     complete: () => {
     },
   });
  }


 getPresignedFile(filename:any) {
  return new Promise((resolve, reject) => { 
    this.__MemberList = this.userAuthService.getPresignedFile({
      key:filename
    }).subscribe({
     next: (x: any) => {
     
      return resolve(x.data.preSignedUrl);
     },
     error: (err: Error) => {
      return resolve(err);
     },
     complete: () => {
     },
   });
  });
  }

  addFamilymembers(): void {
    if (this.familyMemberForm.invalid) {
      if(this.lnguageCode=="en-US"){
        this.toastrService.error("Please fill All the required Fields."); 
      }
      if(this.lnguageCode=="fr-CA"){
        this.toastrService.error("Veuillez remplir tous les champs obligatoires."); 
      } 
      return;
    }
    var phone:any=this.familyMemberForm.getRawValue().usermobile;
    var countryCode=(phone.dialCode);
   var phoneNumber=((phone.number).split(" ").join("")).substring(1);
    var regId:any=[];
    regId.push(this.familyMemberForm.getRawValue().registrationId);
    regId.push(this.familyMemberForm.getRawValue().registrationIdBack);

    this.__MemberList = this.userAuthService.addFamilymembers({
        memberType: this.familyMemberForm.getRawValue().usermemberType,
        firstName: this.familyMemberForm.getRawValue().userfirstname,
        lastName: this.familyMemberForm.getRawValue().userlastname,
        address: this.familyMemberForm.getRawValue().useraddress,
        dob: this.familyMemberForm.getRawValue().userdob,
        countryCode:countryCode,
        mobile: phoneNumber,
        gender: this.familyMemberForm.getRawValue().usergender,
        email: this.familyMemberForm.getRawValue().userEmail,
        registrationIds:regId
      }
    ).subscribe({
      next: (x: any) => {
        this.familyMemberForm.reset();
        this.familyMemberForm.controls['usermemberType'].setValue("");

        if(this.lnguageCode=="en-US"){
          this.toastrService.success("Member added successfully."); 
        }
        if(this.lnguageCode=="fr-CA"){
          this.toastrService.success("Membre ajouté avec succès."); 
        } 

        this.hideAddModal();
        this.getFamilymembers();
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
        if(this.lnguageCode=="en-US"){
          this.toastrService.error(errRes.error.error.clientErrorMessage); 
        }
        if(this.lnguageCode=="fr-CA"){
          this.toastrService.error(errRes.error.error.clientErrorMessageInFrench); 
        } 
      },
      complete: () => {
      },
    });
  }

  UpdateFamilymembers(): void {
    if (this.familyMemberFormEdit.invalid) {
      Object.keys(this.familyMemberFormEdit.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.familyMemberFormEdit.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
      if(this.lnguageCode=="en-US"){
        this.toastrService.error("Please fill All the required Fields."); 
      }
      if(this.lnguageCode=="fr-CA"){
        this.toastrService.error("Veuillez remplir tous les champs obligatoires."); 
      } 
      return;
    }
    var phone:any=this.familyMemberFormEdit.getRawValue().usermobile;
    var countryCode=(phone.dialCode);
    var phoneNumber=((phone.number).split(" ").join("")).substring(1); //remove 0 and spaces

    var regId:any=[];
    var regId:any=[];
    regId.push(this.familyMemberFormEdit.getRawValue().registrationId);
    regId.push(this.familyMemberFormEdit.getRawValue().registrationIdBack);
    this.__MemberList = this.userAuthService.updateFamilymembers({
        familyMemberId: this.familyMemberFormEdit.getRawValue().familyMemberId,
        memberType: this.familyMemberFormEdit.getRawValue().usermemberType,
        firstName: this.familyMemberFormEdit.getRawValue().userfirstname,
        lastName: this.familyMemberFormEdit.getRawValue().userlastname,
        dob: this.familyMemberFormEdit.getRawValue().userdob,
        countryCode:countryCode,
        mobile: phoneNumber,
        gender: this.familyMemberFormEdit.getRawValue().usergender,
        email: this.familyMemberFormEdit.getRawValue().userEmail,
        registrationIds:regId
      }).subscribe({
      next: (x: any) => {
          this.familyMemberFormEdit.reset();
          if(this.lnguageCode=="en-US"){
            this.toastrService.success("Member updated successfully."); 
          }
          if(this.lnguageCode=="fr-CA"){
            this.toastrService.success("Membre mis à jour avec succès."); 
          } 
          //this.memberSuccessMessage = 'Member updated successfully.';
          //this.toastrService.success(this.memberSuccessMessage);
          this.hideEditModal();
          this.getFamilymembers();
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
        if(this.lnguageCode=="en-US"){
          this.toastrService.error(errRes.error.error.clientErrorMessage); 
        }
        if(this.lnguageCode=="fr-CA"){
          this.toastrService.error(errRes.error.error.clientErrorMessageInFrench); 
        } 
      },
      complete: () => {
      },
    });
  }

  async OpenUpdateModal(obj:any){
    let a=obj.countryCode;
    if(obj.countryCode.includes("+")){ 
      a=obj.countryCode.substring(1);
    }
    if(obj.mobile.includes("0")){ 
      obj.mobile.substring(1);
    }
    let country=this.country_List.filter(function (country:any) { return country.phonecode == a });
    this.iso=country[0].sortname;
    this.showEditModal();
    this.familyMemberForm.controls["usermemberType"].setValue(obj.familyMemberId);
    this.familyMemberFormEdit.controls['familyMemberId'].setValue(obj.familyMemberId);
    this.familyMemberFormEdit.controls['userfirstname'].setValue(obj.firstName);
    this.familyMemberFormEdit.controls['userlastname'].setValue(obj.lastName);
    this.familyMemberFormEdit.controls['userdob'].setValue(obj.dob);
    //this.familyMemberFormEdit.controls['countryCode'].setValue(obj.countryCode);//#####
    this.familyMemberFormEdit.controls['usermobile'].setValue(obj.mobile); //#####
    this.familyMemberFormEdit.controls['usergender'].setValue(obj.gender);
    this.familyMemberFormEdit.controls['usermemberType'].setValue(obj.memberType);
    this.familyMemberFormEdit.controls['userEmail'].setValue(obj.email);
    this.familyMemberFormEdit.controls['usergender'].setValue(obj.gender);
   
    var myarr:any;
    var myarr1:any;
    if(obj.registrationIds!=null && obj.registrationIds.length>0){
      if(obj.registrationIds[0]!=null && obj.registrationIds[0]!=""){
      
        this.regIdType = this.getExtension(obj.registrationIds[0]);
      }
      if(obj.registrationIds[1]!=null && obj.registrationIds[1]!=""){
       
        this.regIdTypeBack = this.getExtension(obj.registrationIds[1]);
      }
      this.familyMemberFormEdit.controls["registrationId"].setValue(obj.registrationIds[0]);
      this.familyMemberFormEdit.controls["registrationIdBack"].setValue(obj.registrationIds[1]);

      this.regIdFront1= await this.getPresignedFile(obj.registrationIds[0]);
      this.regIdBack1= await this.getPresignedFile(obj.registrationIds[1]);
      console.log(this.regIdFront1);
    }
  }

  showAddModal(){
    $('#addMemberProfile').offcanvas('show');
    this.form_type="add";
  }
  showEditModal(){
    $('#editMemberProfile').offcanvas('show');
    this.form_type="edit";
  }

  hideAddModal(){
    $('#addMemberProfile').offcanvas('hide');
    this.form_type="";
    this.regIdType="";
    this.regIdTypeBack="";
  }
  hideEditModal(){
    $('#editMemberProfile').offcanvas('hide');
    this.form_type="";
     this.regIdType="";
    this.regIdTypeBack="";
  }

  getCountries():  Promise<any> {
    return new Promise((resolve, reject) => {
      this.userAuthService.getcountries().subscribe({
          next: (result: any) => {
           
            return resolve(result.data.countriesList);
          },
          error: (err: any) => {
            return reject(err.error);
          },
          complete: () => {
          }
      }); 
    });
  }

  DeleteMember(obj:any){
      this.__MemberDelete = this.userAuthService.deleteFamilymember({
      familyMemberId: obj.familyMemberId,
      reason: ""
    }).subscribe({
      next: (x: any) => {
        if(this.lnguageCode=="en-US"){
          this.toastrService.success("Member Removed successfully."); 
        }
        if(this.lnguageCode=="fr-CA"){
          this.toastrService.success("Membre supprimé avec succès."); 
        } 
        //this.memberSuccessMessage = 'Member Removed successfully.';
        //this.toastrService.success(this.memberSuccessMessage);
        this.getFamilymembers();
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
        if(this.lnguageCode=="en-US"){
          this.toastrService.error(errRes.error.error.clientErrorMessage); 
        }
        if(this.lnguageCode=="fr-CA"){
          this.toastrService.error(errRes.error.error.clientErrorMessageInFrench); 
        } 
      },
      complete: () => {
      },
    });
  }

  confirmDialog(obj:any): void {
    let message:any;
    let dialogData:any

    if(this.lnguageCode=="en-US"){
      message = `Are you sure you want to remove this member?`;
      dialogData = new ConfirmDialogModel("Confirm Removal!", message);
    }
    if(this.lnguageCode=="fr-CA"){
      message = `Êtes-vous sûr de vouloir supprimer ce membre ?`;
      dialogData = new ConfirmDialogModel("Confirmez la suppression!", message);
    } 
    
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if(this.result==true){
        this.DeleteMember(obj);
      } 
    });
  }

  getCurrentUser(): any {
    let data = localStorage.getItem('user');
    if (data != null) {
      this._user = JSON.parse(data);
    }
    return this._user;
  }

  GetProfileDetail(): any {
    this.userAuthService.getuserByToken({
        token:this._user.token
    }).subscribe({
      next: (x: any) => {
        this.myProfile=x.data;
        let a=x.data.countryCode;
        if(x.data.countryCode.includes("+")){ 
          a=x.data.countryCode.substring(1);
        }
        if(x.data.mobile.includes("0")){ 
          x.data.mobile.substring(1);
        }

        let country=this.country_List.filter(function (country:any) { return country.phonecode ==a});
        this.iso=country[0].sortname;
        this.familyMemberForm.controls['userEmail'].setValue(this.myProfile.email);
        this.familyMemberForm.controls['usermobile'].setValue(this.myProfile.mobile);
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
        localStorage.removeItem('user')
        this.router.navigate(['/signin']);
      },
      complete: () => {
      },
    });
  }

  uploadFile(event:any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles !== undefined) {
      this.fileName = this.selectedFiles.item(0)?.name;
      const profile_imageh = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.profile_image = reader.result);
      reader.readAsDataURL(profile_imageh);
    } else {
      this.fileName = "";
    }
    var type = "registrationId";
    this.updateProfilePic(type);
  }

  uploadFile1(event:any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles !== undefined) {
      this.fileName = this.selectedFiles.item(0)?.name;
      const profile_imageh = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.profile_image = reader.result);
      reader.readAsDataURL(profile_imageh);
    } else {
      this.fileName = "";
    }
    var type = "registrationIdBack";
    this.updateProfilePic(type);
  }

  updateProfilePic(type: any = ""): void {
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      if (file) {
        this.profilePic = file;
      }
    }
    let docname = "";
    if(type=="registrationIdBack"){
      docname = "Registration ID Back";
    } else {
      docname = "Registration ID Front";
    }
    this._UploadPic = this.userAuthService
      .UploadDocument({
        docName: docname,
        userId: "",
        doc: this.profilePic,
        memberId: "",
      })
      .subscribe({
        next: (result: any) => {
          var myarr:any;
          var myarr1:any;
          if(type=="registrationIdBack"){
              
              if(result.data.docUrl!=null && result.data.docUrl!=""){
                
                this.regIdTypeBack = this.getExtension(result.data.key);
              }

              if(this.form_type=="add"){
                this.regIdBack=result.data.docUrl;
                //this.familyMemberForm.controls["registrationId"].setValue(result.data.docUrl);
                this.familyMemberForm.controls["registrationIdBack"].setValue(result.data.key);
              }else{
                this.regIdBack1=result.data.docUrl;
                //this.familyMemberFormEdit.controls["registrationId"].setValue(result.data.docUrl);
                this.familyMemberFormEdit.controls["registrationIdBack"].setValue(result.data.key);
              }
            
          }else {
            if(result.data.docUrl!=null && result.data.docUrl!=""){
              
              this.regIdType = this.getExtension(result.data.key);
           

            if(this.form_type=="add"){
              this.regIdFront=result.data.docUrl;
              this.familyMemberForm.controls["registrationId"].setValue(result.data.key);
              //this.familyMemberForm.controls["registrationIdBack"].setValue(result.data.docUrl);
            }else{
              this.regIdFront1=result.data.docUrl;
              this.familyMemberFormEdit.controls["registrationId"].setValue(result.data.key);
              //this.familyMemberFormEdit.controls["registrationIdBack"].setValue(result.data.docUrl);
            }
          } 
            
          }
          return;
        },
        error: (err: any) => {
          if (err.error.error.profile_pic) {
            this.toastrService.error(err.error.error.profile_pic, "");
          } else {
            this.toastrService.error(err.error.message, "");
          }
        },
        complete: () => {},
      });
  }

  getExtension(docurl:any){
    var myarr = docurl.split("/");
    console.log(myarr.length);
    var myimg=myarr[myarr.length-1]?myarr[myarr.length-1]:'';
    var myimgg = myimg.split(".");
   // console.log(myimgg.length);
    return myimgg[myimgg.length-1]?myimgg[myimgg.length-1]:'';
  }

}
