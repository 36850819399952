import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { AuthGuardGuard } from '../_services/auth/auth-guard.service';
import { FamilyUpdateProfileComponent } from './family-update-profile/family-update-profile.component';

const routes: Routes = [
  {
    path: 'user',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'my-account',
        component: UpdateProfileComponent
      },
      {
        path: 'family-members',
        component: FamilyUpdateProfileComponent
      },
      {
        path: 'request-proposal',
        loadChildren: () => import('./request-proposal/request-proposal.module').then(m => m.RequestProposalModule)
      },
      {
        path: 'personalised-offer',
        loadChildren: () => import('./personalised-offer/personalised-offer.module').then(m => m.PersonalisedOfferModule)
      }
      ,
      {
        path: 'general-offer',
        loadChildren: () => import('./general-offer/general-offer.module').then(m => m.GeneralOfferModule)
      }
      ,
      {
        path: 'contracts',
        loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule)
      }
      ,
      {
        path: 'documents',
        loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule)
      },
      {
        path: 'message',
        loadChildren: () => import('./message/message.module').then(m => m.MessageModule)
      },
      {
        path: 'claim',
        loadChildren: () => import('./claim/claim.module').then(m => m.ClaimModule)
      }
    ],
    canActivate: [AuthGuardGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserAdminRoutingModule { }
