import {
  AbstractControl,
  ValidatorFn,
  FormControl,
  FormGroup
} from '@angular/forms';

export interface ValidationResult {
    [key: string]: boolean;
}

export class CustomValidators {
  constructor() { }

  static onlyChar(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value == '') return null;

      let re = new RegExp('^[a-zA-Z ]*$');
      if (re.test(control.value)) {
        return null;
      } else {
        return { onlyChar: true };
      }
    };
  }
  static mustMatch(controlName: string, checkControlName: string): ValidatorFn  {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);
      //console.log(control);
      //console.log(checkControl);
      if (checkControl?.errors && !checkControl.errors['matching']) {
        return null;
      }
      
      if (control?.value !== checkControl?.value) {
        controls.get(checkControlName)?.setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }
  
  static requiredFileType( type: string ) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value == '') return null;
      const file = control.value;
      if ( file ) {
        const extension = file.split('.')[1].toLowerCase();
        if ( type.toLowerCase() !== extension.toLowerCase() ) {
          return {
            requiredFileType: true
          };
        }
        return null;
      }
      return null;
    };
  }
  
  static strong(control: FormControl): ValidationResult {
        let hasNumber = /\d/.test(control.value);
        let hasUpper = /[A-Z]/.test(control.value);
        let hasLower = /[a-z]/.test(control.value);
        //let hasSpecial = /[$@$!%*?&]/.test(control.value);
        let hasSpecial = /[~`!$@$!%*?&()_+={}<>,.:;]/.test(control.value);
        // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
        const valid = hasNumber && hasUpper && hasLower && hasSpecial;
        if (!valid) {
            // return what´s not valid
            return { strong: true };
        }
        return null;
    }
}