import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Router} from '@angular/router';
import { UserAuthService } from '../../_services/user-auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @ViewChild('privacy', { static: true }) privacy: TemplateRef<any>;
  @ViewChild('closeModal') private closeModal: ElementRef;
  _isUserLoggedIn:any;
  _user:any;
  _signOutSub:any;
  _getProfile:any;
  notificationList:any=[]
  _getLoggedInuser:any={
    firstName:''
  };
  logoutErrorMessage:any;
  notificationCount:any;
  totalNotification:any;
  totalUnreadNotification:any;
  und: any=0;
  cnt: any=0;
  lnguageCode:any;
  userTncSattus: any;
  profilepicurl: any;
  
  constructor(private modalService: NgbModal, private router:Router, private userAuthService:UserAuthService,private cookieService:CookieService,private toastrService:ToastrService){
    this.lnguageCode=this.cookieService.get('selectedCountryCode');  //en-US // fr-CA
  }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (this.isUserLoggedIn()) {
      this.getCurrentUser();
      this.GetProfileDetail();
    
      this.checkTnc();
    
      
      setInterval(() => {
        this.getNotification();
       
      //  this.PrivacyModal('privacy');
      }, 5000);
    }
  }

  PrivacyModal(content:any) {
		this.modalService.open(content, { centered: true, modalDialogClass: 'privacy-modal', size:'xl', backdrop: 'static' });
	}

  checkTnc(){
    this.userAuthService.getcheckTnc({
        userId:this._user.userId,
        tncId:'123'
      }
    ).subscribe({
      next: (x: any) => {
          this.userTncSattus=x.data.isSaved;
          if(this.userTncSattus==false){
            this.PrivacyModal(this.privacy);
          } else {

          }
         
      },error: (err: Error) => {
        let errRes: any;
        errRes = err;
      },
      complete: () => {
      },
    });
  }


  SaveUserTnc(){
    this.userAuthService.saveUserTnc({
        userId:this._user.userId,
        tncId:'123'
      }
    ).subscribe({
      next: (x: any) => {

        
          // this.userTncSattus=x.data.isSaved;
          // if(this.userTncSattus==false){
          //   this.PrivacyModal(this.privacy);
          // }
         
      },error: (err: Error) => {
        let errRes: any;
        errRes = err;
      },
      complete: () => {
      },
    });
  }

  getNotification(){
    this.userAuthService.getNotification({
        userId:this._user.userId
      }
    ).subscribe({
      next: (x: any) => {
          this.notificationList=x.data.notificationList;
          this.totalNotification=x.data.notificationList.length;
          let unredcnt: any=localStorage.getItem('unreadnCnt')?localStorage.getItem('unreadnCnt'):0;
          if(this.totalNotification>unredcnt){
          this.cnt=parseInt(this.totalNotification)-parseInt(unredcnt);
          this.totalUnreadNotification= this.cnt;
          this.und =parseInt(unredcnt)+parseInt(this.cnt);
          }else if(this.totalNotification==unredcnt) {
              this.totalUnreadNotification=0;
          }else {
            this.totalUnreadNotification=this.totalNotification;
          }

          if(this.totalUnreadNotification==0){
            this.notificationCount=null;
          }else{
            this.notificationCount=this.totalUnreadNotification
          }
      },error: (err: Error) => {
        let errRes: any;
        errRes = err;
      },
      complete: () => {
      },
    });
  }

  viewNotification(){
    let unredcnt: any=localStorage.getItem('unreadnCnt');
    if(this.totalNotification>unredcnt){
      this.totalUnreadNotification=0;
      localStorage.setItem('unreadnCnt', this.totalNotification);
      localStorage.setItem('tunreadnCnt', this.totalUnreadNotification);
      localStorage.setItem('unreadnCnt', this.und);
      localStorage.setItem('tunreadnCnt', this.cnt);
    }else{
      this.totalUnreadNotification=0;
      localStorage.setItem('unreadnCnt', this.totalNotification);
      localStorage.setItem('tunreadnCnt', this.totalUnreadNotification);
      localStorage.setItem('unreadnCnt', this.und);
      localStorage.setItem('tunreadnCnt', this.cnt);
    }
    if(this.totalUnreadNotification==0){
      this.notificationCount=null;
    }else{
      this.notificationCount=this.totalUnreadNotification
    }
  }

  isUserLoggedIn(): boolean {
    let data = localStorage.getItem('user');
    if (data != null) {
      return this._isUserLoggedIn = true;
    }
    return this._isUserLoggedIn = false;
  }
  getCurrentUser(): any {
    let data = localStorage.getItem('user');
    if (data != null) {
      this._user = JSON.parse(data);
    }
    return this._user;
  }

  async GetProfileDetail() {
    this._getProfile = this.userAuthService.getuserByToken({
        token:this._user.token
    }).subscribe({
      next: async (x: any) => {
       this._getLoggedInuser=x.data;
       this.profilepicurl=await this.getPresignedFile(x.data.profilePic);
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
        localStorage.removeItem('user')
        this.router.navigate(['/signin']);
      },
      complete: () => { 
      },
    });
  }
  async getPresignedFile(filename:any) {
    return new Promise((resolve, reject) => { 
      this.userAuthService.getPresignedFile({
        key:filename
      }).subscribe({
       next: (x: any) => {
       
        return resolve(x.data.preSignedUrl);
       },
       error: (err: Error) => {
        return resolve(err);
       },
       complete: () => {
       },
     });
    });
    }

  SignOut(): any {
    localStorage.removeItem('user');
    this._signOutSub = this.userAuthService.signout({
      token:this._user.token,
      hardSignOut:true
    }).subscribe({
      next: (x: any) => {
        localStorage.removeItem('user');
        localStorage.clear();
        setTimeout(() => {
          this.router.navigate(['/signin']);
        }, 2000);
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
        localStorage.clear();
        this.router.navigate(['/signin']);
        //this.logoutErrorMessage = errRes.error.message;

        if(this.lnguageCode=="en-US"){
          this.toastrService.error(errRes.error.error.clientErrorMessage); 
        }
        if(this.lnguageCode=="fr-CA"){
          this.toastrService.error(errRes.error.error.clientErrorMessageInFrench); 
        } 
      },
      complete: () => {
        localStorage.removeItem('user');
      },
    });
  }

}
