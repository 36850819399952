import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../../src/environments/environment'
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
 
  _headers: any;
  httpOptions:any;
  _headersWithToken: any;
  _headersWithFile: any;
  _baseURL: string;
  _CommunicatorbaseURL: string;
  _DocumentbaseURL: string;
  _user: any = {};
  deviceInfo:any;
  _headersOTP:any;
  deviceinfoforlogin:any;
  private _openLoginForm = new Subject<void>();
  _InsurancebaseURL: any;
  catalogue:any;
  fcmKey:any;
  communication_url:any;
  constructor( private httpClient: HttpClient, private deviceService:DeviceDetectorService,private angularFireMessaging: AngularFireMessaging) {
    this.askForPermissioToReceiveNotifications();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    let devicedetail:any= {
      platform: 'Website',
      browser: this.deviceInfo.browser,
      browserVersion: this.deviceInfo.browser_version,
      osVersion: this.deviceInfo.os_version,
      deviceId: this.deviceInfo.device + this.deviceInfo.deviceType,
      appVersion: '',
      ipAddress: '',
      macAddress: '',
    }
    this.deviceinfoforlogin=devicedetail;
   this._headers = new HttpHeaders().set('content-type', 'application/json').set('User_Agent', JSON.stringify(devicedetail));
   this._headersOTP = new HttpHeaders().set('content-type', 'application/json').set('data-hash', 'test').set('device-id', 'test').set('User_Agent', JSON.stringify(devicedetail));
   //this._headersWithFile = new HttpHeaders().set('User_Agent', JSON.stringify(devicedetail)); 
     
    this._baseURL = environment.USERAPIENDPOINT;
    this._CommunicatorbaseURL = environment.COMMUNICATORAPIURL;
    this._DocumentbaseURL = environment.DOCUMENTAPIURL;
    this._InsurancebaseURL = environment.INSURANCEAPIURL;
    this.catalogue=environment.CATALOGUE;
    this.communication_url=environment.COMMUNICATION;

    if (this.isUserLoggedIn()) {
      this.getCurrentUser();
      this._headersWithToken = new HttpHeaders().set('content-type', 'application/json').set('Access-Token',this._user.token).set('User_Agent', JSON.stringify(devicedetail));
      this._headersWithFile = new HttpHeaders().set('User_Agent', JSON.stringify(devicedetail)).set('Access-Token',this._user.token); 
     
    }
	}



	isUserLoggedIn(): boolean {
    let data = localStorage.getItem('user');
    if (data != null) {
      return true;
    }
    return false;
  }
  
  getCurrentUser(): any {
    let data:any = localStorage.getItem('user');
    if (data != null) {
      this._user = JSON.parse(data);
    }
    return this._user;
  }

  async askForPermissioToReceiveNotifications() {//new
    await this.angularFireMessaging.requestPermission.subscribe(
      (response) => {

      },
      (error) => {
        console.error(error);
      }
    );
    const token = await this.angularFireMessaging.getToken.subscribe(
      (token: any) => {
        this.fcmKey = token ? token : "";
      },
      (error) => {
        console.error(error);
      }
    );
  }
  
  login(obj: any): Observable<any> {
    let loginheader = new HttpHeaders().set('content-type', 'application/json').set('User_Agent', JSON.stringify(this.deviceinfoforlogin)).set('App-Token', this.fcmKey);
    
    return this.httpClient.post(this._baseURL + 'user/signin', obj, { headers: loginheader });
  }

  registration(obj: any): Observable<any> {
    let loginheader = new HttpHeaders().set('content-type', 'application/json').set('User_Agent', JSON.stringify(this.deviceinfoforlogin)).set('App-Token', this.fcmKey);
    
    return this.httpClient.post(this._baseURL + 'user/signup', obj, { headers: loginheader });
  }

  getNotification(obj: any): Observable<any> {//working
  
    return this.httpClient.post(this.communication_url + 'get/notification', obj);
  }

  signout(obj: any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'user/signout', obj, { headers: this._headersWithToken });
  }

  verifyotp(obj: any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'user/signup/verify/otp', obj, { headers: this._headers });
  }

  verifyotpwithaction(obj: any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'user/perform/action', obj, { headers: this._headers });
  }

  resendotp(obj: any): Observable<any> {
  
    return this.httpClient.put(this._CommunicatorbaseURL + 'otp/resend', obj, { headers: this._headersOTP });
  }

  getuserByToken(obj: any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'user/details/token', obj, { headers: this._headersWithToken });
  }

  getuserDByToken(): Observable<any> {
   
    return this.httpClient.post(this._baseURL + 'user/details/token', {token : this._user.token}, { headers: this._headersWithToken });
  }
  
  forgetpassword(obj: any): Observable<any> {
  
    return this.httpClient.put(this._baseURL + 'user/forgot/password', obj, { headers: this._headers });
  }

  UpdateProfile(obj: any, userId:any): Observable<any> {
  
    return this.httpClient.put(this._baseURL + 'user/update', {userId: userId, userDetail: obj}, { headers: this._headersWithToken });
  }

  UpdatePassword(obj: any): Observable<any> {
  
    return this.httpClient.put(this._baseURL + 'user/change/password', {token: this._user.token, oldPassword: obj.oldPassword, newPassword: obj.newPassword}, { headers: this._headersWithToken });
  }

  UpdateEmail(obj: any): Observable<any> {
  
    return this.httpClient.put(this._baseURL + 'user/email/update', {token: this._user.token, newEmail: obj.newEmail}, { headers: this._headersWithToken });
  }

  VerifyOTPEmail(obj: any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'user/verify/email/update', obj, { headers: this._headersWithToken });
  }

  UpdateMobile(obj: any): Observable<any> {
    obj["token"]=this._user.token;
    // {token: this._user.token, newMobile: obj.newMobile}
  
    return this.httpClient.put(this._baseURL + 'user/mobile/update', obj, { headers: this._headersWithToken });
  }

  VerifyOTPMobile(obj: any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'user/verify/mobile/update', obj, { headers: this._headersWithToken });
  }

  setpassword(obj: any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'user/verify/forgot/password', obj, { headers: this._headers });
  }
  
  getcountries(): Observable<any> {
  
    return this.httpClient.get(this._baseURL + 'config/get/countries', { headers: this._headers });
  }
  
  getstates(obj: any): Observable<any> {
  
    return this.httpClient.get(this._baseURL + 'config/get/states/'+obj, { headers: this._headers });
  }
  
  getcities(obj: any): Observable<any> {
  
    return this.httpClient.get(this._baseURL + 'config/get/cities/'+obj, { headers: this._headers });
  }

  getagents(obj: any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'user/get/agent/users', obj, { headers: this._headers });
  }

  getFamilymembers(obj:any=''): Observable<any> {
    let membertype=obj.relation;
  
    return this.httpClient.post(this._baseURL + 'user/get/user/family-member', { userId: this._user.userId, relation: membertype}, { headers: this._headersWithToken });
  }
  getFamilymember(obj:any=''): Observable<any> {
    return this.httpClient.post(this._baseURL + 'user/get/family-member', obj, { headers: this._headersWithToken });
  }

  getuserDashboardInfo(obj:any=''): Observable<any> {
    return this.httpClient.post(this._InsurancebaseURL + 'get/user/dashboard-info', obj, { headers: this._headersWithToken });
  }

  
  addFamilymembers(obj:any): Observable<any> {
    
    obj['userId'] = this._user.userId;
  
    return this.httpClient.post(this._baseURL + 'user/add/family-member', obj, { headers: this._headersWithToken });
  }

  

  updateFamilymembers(obj:any): Observable<any> {
    
    //obj['userId'] = this._user.userId;
  
    return this.httpClient.put(this._baseURL + 'user/update/family-member', obj, { headers: this._headersWithToken });
  }

  deleteFamilymember(obj:any): Observable<any> {
   
    return this.httpClient.post(this._baseURL + 'user/delete/family-member', obj, { headers: this._headersWithToken });
  }

   UploadDocument(obj:any): Observable<any> {
    var form = new FormData();
    form.append("docName", obj.docName);
    form.append("userId", this._user?.userId ? this._user?.userId : '');
    form.append("memberId", obj.memberId ? obj.memberId : '');
    form.append("uploadedBy", this._user?.userId ? this._user?.userId : '');
    if(obj.doc !== undefined){ 
      form.append("doc",obj.doc, obj.doc.name);
    }

    return this.httpClient.post(this._DocumentbaseURL+'upload', form, { headers: this._headersWithFile });
  }

  getPresignedFile(obj:any): Observable<any> {
   
    return this.httpClient.post(this._DocumentbaseURL + 'get/presigned', obj, { headers: this._headersWithToken });
  }

  _getPresignedFileUrl(filename:any) {
    let obj={
      key:filename
    }
    ///let headerss= new HttpHeaders().set('Access-Token',this.user.token);
    return new Promise((resolve, reject) => { 
      this.httpClient.post(this._DocumentbaseURL + 'get/presigned', obj, { headers: this._headersWithToken }).subscribe({
       next: (x: any) => {
       
        return resolve(x.data.preSignedUrl);
       },
       error: (err: Error) => {
        return reject(err);
       },
       complete: () => {
       },
     });
    });
  } 

  addProposalRequest(obj:any): Observable<any> {
   
    return this.httpClient.post(this._InsurancebaseURL + 'request/proposal', obj, { headers: this._headersWithToken });
  }

  GetStaticDataList(obj:any=''): Observable<any> {
    return this.httpClient.post(this._InsurancebaseURL + 'get/static/data', obj, { headers: this._headersWithToken });
  }
  getProposalList(obj:any): Observable<any> {
    obj['userId'] = this._user.userId;
    return this.httpClient.post(this._InsurancebaseURL + 'get/user/leads', obj, { headers: this._headersWithToken });
  }

  getLeadList(obj:any): Observable<any> {
    obj['entityType'] = 'USER_ID';
    obj['entityId'] = this._user.userId;
    return this.httpClient.post(this._InsurancebaseURL + 'get/leads', obj, { headers: this._headersWithToken });
  }
  
  getProposalOfferList(obj:any): Observable<any> {
   // obj['userId'] = this._user.userId;
    return this.httpClient.post(this._InsurancebaseURL + 'get/lead/user/proposals', obj, { headers: this._headersWithToken });
  }

  getGeneralOfferList(obj:any): Observable<any> {
    obj['userId'] = this._user.userId;
    return this.httpClient.post(this._InsurancebaseURL + 'get/user/proposal', obj, { headers: this._headersWithToken });
  }

  getGeneralOfferLists(obj:any): Observable<any> {
    obj['entityType'] = obj.entityType;
    obj['entityId'] = this._user.userId;
    return this.httpClient.post(this._InsurancebaseURL + 'get/proposals', obj, { headers: this._headersWithToken });
  }

  updateProposalState(obj:any): Observable<any> {
    return this.httpClient.put(this._InsurancebaseURL + 'update/proposal/state', obj, { headers: this._headersWithToken });
  }

  GetProposalDetail(obj:any): Observable<any> {
    // obj['userId'] = this._user.userId;
     return this.httpClient.post(this._InsurancebaseURL + 'get/proposal', obj, { headers: this._headersWithToken });
   }

   contracts(obj:any): Observable<any> {
    //return true;
    // obj['userId'] = this._user.userId;
     return this.httpClient.post(this._InsurancebaseURL + 'get/proposal', obj, { headers: this._headersWithToken });
   }

   
   getDocumentMembers(obj:any): Observable<any> {
    //return true;
    obj['userId'] = this._user.userId;
     return this.httpClient.post(this._DocumentbaseURL + 'get/user/docs', obj, { headers: this._headersWithToken });
   }
   getMemberDocuments(obj:any): Observable<any> {
    //return true;
    obj['userId'] = this._user.userId;
     return this.httpClient.post(this._DocumentbaseURL + 'get/member/docs', obj, { headers: this._headersWithToken });
   }

   sendPushNotification(obj: any): Observable<any> {
    let serverKey = environment.firebaseServerKey;
    let hh = new HttpHeaders({
      'Authorization': 'key=' + serverKey,
      'Content-type': 'application/json'
    });

    return this.httpClient.post(environment.firebaseFcmUrl, obj, { headers: hh });
  }
  
  get openLoginForm() {
    return this._openLoginForm;
  }

  getInsuranceCmpnayData(obj:any): Observable<any> {
     return this.httpClient.post(this.catalogue + 'category/get/all', obj, { headers: this._headersWithToken });
   }
   getCliamForm(obj:any): Observable<any> {
    return this.httpClient.post(this._InsurancebaseURL + 'get/claim-config', obj, { headers: this._headersWithToken });
  }
   saveClaimForm(obj:any): Observable<any> {
    return this.httpClient.post(this._InsurancebaseURL + 'save/claim', obj, { headers: this._headersWithToken });
  }
  getcheckTnc(obj:any): Observable<any> {
    return this.httpClient.post(this._baseURL + 'user/check/user-tnc', obj, { headers: this._headersWithToken });
  }

  saveUserTnc(obj:any): Observable<any> {
    return this.httpClient.post(this._baseURL + 'user/save/user-tnc', obj, { headers: this._headersWithToken });
  }

  
}
