import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { UserAuthService } from 'src/app/_services/user-auth.service';
import { Globals } from 'src/app/globals';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})

export class DashboardComponent implements OnInit {
  DATE_FORMAT:any=environment.DATE_FORMAT;
  _getDasboard: any;
  _user: any;
  Dashboarddata: any;
  __ProposalList: any;
  proposal_List: any;
  lngData: any;
  lnguageCode: any;

  constructor(private userAuthService:UserAuthService, private globals:Globals,private cookieService:CookieService){
    let data = localStorage.getItem('user');
    if (data != null) {
      this._user = JSON.parse(data);
    }
    this.lngData=this.globals._getLangData();
    this.lnguageCode=this.cookieService.get('selectedCountryCode').substr(0, 2);
  }

  ngOnInit(): void {
    this.GetDashboardDetail();
    this.getLeadList();
  }

  GetDashboardDetail(): any {
    this._getDasboard = this.userAuthService.getuserDashboardInfo({
        userId: this._user.userId
      }).subscribe({
      next: (x: any) => {
       this.Dashboarddata=x.data;
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
      },
      complete: () => {
      },
    });
  }

  getLeadList(): void {
    this.__ProposalList = this.userAuthService.getLeadList({
      status:'',
      pageSize:5,
      pageCount:1
    }).subscribe({
     next: (x: any) => {
      this.proposal_List=x.data.leadsList;
     },
     error: (err: Error) => {
       let errRes: any;
       errRes = err;
     },
     complete: () => {
     },
   });
   
 }

}
