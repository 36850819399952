<div class="right-block">
	<section class="dashboard-content">
		<div class="dashboard-card">
			<nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
				<div class="breadcrumb">
				  <li class="breadcrumb-item"><a href="javascript:void(0);" routerLink="/user" i18n>Dashboard</a></li>
				  <li class="breadcrumb-item active" aria-current="page" i18n>Family Member Profile</li>
				</div>
			</nav>
			<div class="dashboard-title-block mb-4">
				<div class="h3" i18n>Family Member Profile</div>
			</div>
			<div class="custom-table">
				<div class="cus-table-search mb-3">
					<div class="row justify-content-between mb-3 gx-3 gx-lg-4 align-items-center member_filter">
						<div class="col-6 col-lg-5 col-xl-4">
							<div class="head-left">
								<select [(ngModel)]="memberType" class="form-control w-100 w-select" (change)="getFamilymembers()" name="" id="">
									<option value="" i18n>Select Relation</option>
									<option value="SELF" i18n>Self</option>
									<option value="CHILDREN" i18n>Children</option>
									<option value="SPOUSE" i18n>Spouse</option>
								</select>
							</div>
							<!--<div class="head-right">
								<div class="form-search-block">
								<input type="text" class="form-control" placeholder="Search">
								<button class="btn-search"><i class="bi bi-search"></i></button>
								</div>
							</div>-->
						</div>
						<div class="col-6 col-sm-5 col-md-6 col-lg-4 col-xl-3 text-end">
							<button class="btn btn-primary" type="button" data-bs-toggle="offcanvas"  (click)="showAddModal()"><i class="bi bi-plus-lg me-1"></i><ng-container i18n> Add Profile</ng-container></button> <!--data-bs-target="#addMemberProfile" aria-controls="addMemberProfile"-->
						</div>
					</div>
				</div>

				<div class="scroll_tbl">
					<table class="table">
						<thead>
							<tr>
								<th>
									<div class="cusCheckbox"  i18n> User Name/Type </div>
								</th>
								<!-- <th>Partner Name</th>-->
								<th  i18n>Email/Phone</th>
								<!-- <th>Address</th> -->
								<!-- <th>Country/City</th>-->
								<th  i18n>Action</th>
							</tr>
						</thead>
	
						<tbody>
							<tr *ngFor="let member of member_List; let i = index">
								<td> {{member.firstName}} {{member.lastName}} <span class="text-600 mt-1 d-block">{{lngData[member.memberType+'_'+languageCode]}}</span> </td>
								<!-- <td></td>-->
								<td> {{member.email}} <span class="text-600 mt-1 d-block">{{member.countryCode}}-{{member.mobile}}</span></td>
								<!-- <td>{{member.address}}</td> -->
								<!-- <td></td>-->
								<td> <a *ngIf="member.memberType!='SELF'" class="link-default" href="javascript:void(0);" (click)="OpenUpdateModal(member)" i18n>Edit</a> <a *ngIf="member.memberType!='SELF'" class="link-danger" href="javascript:void(0);" (click)="confirmDialog(member)" i18n>Remove</a> </td>
							</tr>
							<tr *ngIf="member_List.length < 1">
								<td colspan="6">
									<p style="text-align:center;" i18n> No Record Found</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				
			</div>
		</div>
	</section>
</div>


<div class="offcanvas offcanvas-end edit-profile-offcanvas" tabindex="-1" id="addMemberProfile" aria-labelledby="addMemberProfileLabel">
	<div class="offcanvas-header">
		<h5 class="offcanvas-title" id="addMemberProfileLabel" i18n>Add Profile</h5>
		<button type="button" class="btn-close text-reset"  aria-label="Close" (click)="hideAddModal()" i18n-lebel></button> <!--class="btn-close text-reset" data-bs-dismiss="offcanvas"-->
	</div>
	<div class="offcanvas-body pt-0">
		<form [formGroup]="familyMemberForm" (ngSubmit)="addFamilymembers()" #fmemberErrorss="ngForm">
			<div class="row row-cols-md-1 mb-3 mb-md-3">
				<div class="col form-group">
					<label class="mb-1" i18n>Member Type <sup>*</sup></label>
					<select formControlName="usermemberType" class="form-control">
						<option value="" i18n>Select</option>
						<option [value]="'SPOUSE'" i18n>Spouse</option>
						<option [value]="'CHILDREN'" i18n>Children</option>
					</select>
					<div *ngIf="fmemberError['usermemberType'].invalid && (fmemberError['usermemberType'].dirty || fmemberError['usermemberType'].touched || fmemberErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberError['usermemberType'].errors?.['required']" i18n> This field is required. </div>
					</div>
				</div>
			</div>
			<div class="row row-cols-1 row-cols-lg-2 mb-4 mb-md-4 mt-4 mt-md-4 gy-2">
				<div class="col">
					<label class="mb-1" i18n>First Name <sup>*</sup></label>
					<input type="text" formControlName="userfirstname" class="form-control">
					<div *ngIf="fmemberError['userfirstname'].invalid && (fmemberError['userfirstname'].dirty || fmemberError['userfirstname'].touched || fmemberErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberError['userfirstname'].errors?.['required']" i18n> This field is required. </div>
					</div>
				</div>
				<div class="col">
					<label class="mb-1" i18n>Last Name <sup>*</sup></label>
					<input type="text" formControlName="userlastname" class="form-control">
					<div *ngIf="fmemberError['userlastname'].invalid && (fmemberError['userlastname'].dirty || fmemberError['userlastname'].touched || fmemberErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberError['userlastname'].errors?.['required']" i18n> This field is required. </div>
					</div>
				</div>
			</div>
			<div class="row row-cols-md-1 row-cols-lg-1 mb-4 mb-md-4">
				<div class="col form-group">
					<label class="mb-1" i18n>Date of Birth <sup>*</sup></label>
					<input type="date" onkeydown="return false" formControlName="userdob" max="{{maxDate}}" class="form-control">
					<div *ngIf="fmemberError['userdob'].invalid && (fmemberError['userdob'].dirty || fmemberError['userdob'].touched || fmemberErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberError['userdob'].errors?.['required']" i18n> Date of Birth is required. </div>
						<div *ngIf="fmemberError['userdob'].errors?.['pattern']" i18n> Date of Birth must be a valid date format. </div>
					</div>
				</div>
			</div>
			<div class="row row-cols-md-1 row-cols-lg-1 mb-4 mb-md-4">
				<div class="col form-group">
					<label class="mb-1" i18n>Email <sup>*</sup></label>
					<input type="email" formControlName="userEmail" class="form-control">
					<div *ngIf="fmemberError['userEmail'].invalid && (fmemberError['userEmail'].dirty || fmemberError['userEmail'].touched || fmemberErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberError['userEmail'].errors?.['required']" i18n> This field is required. </div>
						<div *ngIf="fmemberError['userEmail'].errors?.['email']" i18n> Please enter a valid email address. </div>
					</div>
				</div>
			</div>
		
			<div class="row row-cols-md-1 row-cols-lg-1 mb-4 mb-md-4">
				<div class="col form-group">
					<label for="" i18n>Mobile Number <sup>*</sup></label>

					<ngx-intl-tel-input
                            [cssClass]="'custom'"
                            [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="false"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[
                              SearchCountryField.Iso2,
                              SearchCountryField.Name
                            ]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="iso"
                            [maxLength]="15"
                            [phoneValidation]="true"
                            [separateDialCode]="separateDialCode"
                            [numberFormat]="PhoneNumberFormat.National"
                            name="usermobile"
                            formControlName="usermobile"
                          >
                          </ngx-intl-tel-input>

                          <div *ngIf="fmemberError['usermobile'].invalid && (fmemberError['usermobile'].dirty || fmemberError['usermobile'].touched || fmemberErrorss.submitted)" class="invalid">
                            <div *ngIf="fmemberError['usermobile'].errors?.['required'] " i18n>
							Mobile Number is required.
                            </div>
                            <div *ngIf="fmemberError['usermobile'].errors?.['validatePhoneNumber']" i18n>
							Mobile enter valid Phone number.
                            </div>
                          </div>

					<!-- <div class="row gx-1">
						<div class="col-md-5">
							<select class="form-control" formControlName="countryCode">
								<option *ngFor="let country of country_List;" value="{{'+'+country.phonecode}}">{{country.sortname}} (+ {{country.phonecode}} )</option>
							</select>
							<div *ngIf="fmemberError['countryCode'].invalid && (fmemberError['countryCode'].dirty || fmemberError['countryCode'].touched || fmemberErrorss.submitted)" class="invalid">
								<div *ngIf="fmemberError['countryCode'].errors?.['required']"> Country Code is required. </div>
							</div>
						</div>
						<div class="col-md-7">
							<input type="number" class="form-control" formControlName="usermobile" />
							<div *ngIf="fmemberError['usermobile'].invalid && (fmemberError['usermobile'].dirty || fmemberError['usermobile'].touched || fmemberErrorss.submitted)" class="invalid">
								<div *ngIf="fmemberError['usermobile'].errors?.['required']"> Mobile Number is required. </div>
								<div *ngIf="fmemberError['usermobile'].errors?.['pattern']"> Mobile Number must be a valid 9-13 digit number. </div>
							</div>
						</div>
					</div> -->

				</div>
			</div>
			<div class="row">
				<div class="col">
					<label class="mb-1" i18n>Gender <sup>*</sup></label>
					<div *ngIf="fmemberError['usergender'].invalid && (fmemberError['usergender'].dirty || fmemberError['usergender'].touched || fmemberErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberError['usergender'].errors?.['required']" i18n> This field is required. </div>
					</div>
					<div class="plans">
						<label class="plan basic-plan me-1" for="basic">
							<input type="radio" formControlName="usergender" [value]="'Male'" id="basic" />
							<div class="plan-content"> <img src="assets/img/male.png" alt="" />
								<div class="plan-details" i18n> Male </div>
							</div>
						</label>
						<label class="plan complete-plan" for="complete">
							<input type="radio" formControlName="usergender" [value]="'Female'" id="complete" />
							<div class="plan-content"> <img src="assets/img/female.png" alt="" />
								<div class="plan-details" i18n> Female </div>
							</div>
						</label>
						<!-- <label class="plan neutral-plan" for="neutral">
							<input type="radio" formControlName="usergender" [value]="'Neutral'" id="neutral" />
							<div class="plan-content"> <img src="assets/img/neutral.png" alt="" />
								<div class="plan-details"> Neutral </div>
							</div>
						</label> -->
					</div>
				</div>
			</div>
			<!-- <div class="row row-cols-md-1 row-cols-lg-1 mb-4 mb-md-4">
				<div class="col form-group">
				<label class="mb-1">Address <sup>*</sup></label>
				<textarea formControlName="useraddress" class="form-control" placeholder=" " rows="3"></textarea>
				<div *ngIf="fmemberError['useraddress'].invalid && (fmemberError['useraddress'].dirty || fmemberError['useraddress'].touched)" class="invalid">
					<div *ngIf="fmemberError['useraddress'].errors?.['required']">
						This field is required.
					</div>
				</div>
				</div>
			</div> -->

			<div class="row row-cols-1 mb-3 mb-md-4 gy-2">
				<div class="col form-group">
					<label class="mb-1" i18n>Upload Registration ID Front <sup>*</sup></label>
					<div class="file-type-two">
					<input formControlName="registrationId" type="hidden" class="form-control">
					
					<input type="file" id="imageUpload" accept=".png, .PNG, .jpg, .jpeg, .docx, .pdf" #fileInput (change)="uploadFile($event)" class="form-control">
					<i class="bi bi-cloud-arrow-up-fill"></i>
					<span><ng-container i18n="@@dragdrop">Drag & Drop or </ng-container> <span i18n="@@browsefile">browse</span></span>
				</div>
					<div *ngIf="fmemberError['registrationId'].invalid && (fmemberError['registrationId'].dirty || fmemberError['registrationId'].touched || fmemberErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberError['registrationId'].errors?.['required']" i18n> Registration ID Front is required. </div>
					</div>

					<div class="img_preview">
						<div class="bx" *ngIf="regIdType=='png' || regIdType=='jpg' || regIdType=='jpeg' || regIdTypeBack=='PNG' || regIdTypeBack=='JPG' || regIdTypeBack=='JPEG'">
							<figure>
								<a href="{{regIdFront}}" target="_blank"><img src="{{regIdFront}}" alt="Registration img" class="img-fluid" i18n-alt></a>
								<!--{{_userdetailuser.registrationIds[0]}}-->
							</figure>
						</div>
						<div class="bx" *ngIf="regIdType=='pdf' || regIdType=='docx' || regIdTypeBack=='PDF' || regIdTypeBack=='DOCX'">
							<figure><a href="{{regIdFront}}" target="_blank"><i class="bi bi-file-pdf text-danger"></i></a></figure> <a href="javascript:void(0);" class="clos"><i class="bi bi-x"></i></a> </div>
					</div>
				</div>
				<div class="col form-group">
					<label class="mb-1" i18n>Upload Registration ID Back <sup>*</sup></label>
					<div class="file-type-two">
					<input formControlName="registrationIdBack" type="hidden" class="form-control">
					<input type="file" id="imageUpload" accept=".png, .PNG, .jpg, .jpeg, .docx, .pdf" (change)="uploadFile1($event)" class="form-control">
					<i class="bi bi-cloud-arrow-up-fill"></i>
					<span><ng-container i18n="@@dragdrop">Drag & Drop or </ng-container> <span i18n="@@browsefile">browse</span></span>
				
				</div>
					<div *ngIf="fmemberError['registrationIdBack'].invalid && (fmemberError['registrationIdBack'].dirty || fmemberError['registrationIdBack'].touched || fmemberErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberError['registrationIdBack'].errors?.['required']" i18n> Registration ID Back is required. </div>
					</div>

					<div class="img_preview">
						<div class="bx" *ngIf="regIdTypeBack=='png' || regIdTypeBack=='jpg' || regIdTypeBack=='jpeg' || regIdTypeBack=='PNG' || regIdTypeBack=='JPG' || regIdTypeBack=='JPEG'">
							<figure>
								<a href="{{regIdBack}}" target="_blank"><img src="{{regIdBack}}" alt="Registration img" class="img-fluid" i18n-alt></a>
								<!--{{_userdetailuser.registrationIds[1]}}-->
							</figure>
						</div>
						<div class="bx" *ngIf="regIdTypeBack=='pdf' || regIdTypeBack=='docx' || regIdTypeBack=='PDF' || regIdTypeBack=='DOCX'">
							<figure><a href="{{regIdBack}}" target="_blank"><i class="bi bi-file-pdf text-danger"></i></a></figure> <a href="javascript:void(0);" class="clos"><i class="bi bi-x"></i></a> </div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col pro_btns">
					<button type="submit" class="btn btn-primary me-3" i18n>Save Profile</button>
					<button type="button" class="btn btn-transparent btn-danger"  aria-label="Close" (click)="hideAddModal()" i18n>Cancel</button><!--data-bs-dismiss="offcanvas"-->
				</div>
			</div>
		</form>
	</div>
</div>



<div class="offcanvas offcanvas-end edit-profile-offcanvas" tabindex="-1" id="editMemberProfile" aria-labelledby="editMemberProfileLabel">
	<div class="offcanvas-header">
		<h5 class="offcanvas-title" id="editMemberProfileLabel" i18n>Edit Profile</h5>
		<button type="button" class="btn-close text-reset"  aria-label="Close" (click)="hideEditModal()" i18n-alt></button><!--data-bs-dismiss="offcanvas"-->
	</div>
	<div class="offcanvas-body pt-0">
		<form [formGroup]="familyMemberFormEdit" (ngSubmit)="UpdateFamilymembers()" #fmemberEErrorss="ngForm">
			<div class="row row-cols-md-1 mb-3 mb-md-3">
				<div class="col form-group">
					<label class="mb-1" i18n>Member Type</label>
					<select formControlName="usermemberType" class="form-control">
						<option value="" disabled i18n>Select</option>
						<option [value]="'SPOUSE'" disabled i18n>Spouse</option>
						<option [value]="'CHILDREN'" disabled i18n>Children</option>
					</select>
				</div>
			</div>

			<div class="row row-cols-1 row-cols-lg-2 mb-4 mb-md-4 mt-4 mt-md-4 gy-2">
				<div class="col">
          		<input type="hidden" formControlName="familyMemberId" class="form-control"  >

					<label class="mb-1" i18n>First Name <sup>*</sup></label>
					<input type="text" formControlName="userfirstname" class="form-control"   >
					<div *ngIf="fmemberEError['userfirstname'].invalid && (fmemberEError['userfirstname'].dirty || fmemberEError['userfirstname'].touched || fmemberEErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberEError['userfirstname'].errors?.['required']" i18n> This field is required. </div>
					</div>
				</div>

				<div class="col">
					<label class="mb-1" i18n>Last Name <sup>*</sup></label>
					<input type="text" formControlName="userlastname" class="form-control"  >
					<div *ngIf="fmemberEError['userlastname'].invalid && (fmemberEError['userlastname'].dirty || fmemberEError['userlastname'].touched || fmemberEErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberEError['userlastname'].errors?.['required']" i18n> This field is required. </div>
					</div>
				</div>
			</div>

			<div class="row row-cols-md-1 row-cols-lg-1 mb-4 mb-md-4">
				<div class="col form-group">
					<label class="mb-1" i18n>Date of Birth <sup>*</sup></label>
					<input type="date" onkeydown="return false" formControlName="userdob"   max="{{maxDate}}"  class="form-control" name=" " id=" " >
					<div *ngIf="fmemberEError['userdob'].invalid && (fmemberEError['userdob'].dirty || fmemberEError        ['userdob'].touched || fmemberEErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberEError['userdob'].errors?.['required']" i18n>
               This field is required. 
            </div>

					</div>
				</div>
			</div>

			<div class="row row-cols-md-1 row-cols-lg-1 mb-4 mb-md-4">
				<div class="col form-group">
					<label class="mb-1" i18n>Email <sup>*</sup></label>
					<input formControlName="userEmail" type="email" class="form-control"  >
					<div *ngIf="fmemberEError['userEmail'].invalid && (fmemberEError['userEmail'].dirty || fmemberEError['userEmail'].touched || fmemberEErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberEError['userEmail'].errors?.['required']" i18n> This field is required. </div>
						<div *ngIf="fmemberEError['userEmail'].errors?.['email']" i18n> Please enter a valid email address. </div>
					</div>
				</div>
			</div>

      		<div class="row row-cols-md-1 row-cols-lg-1 mb-4 mb-md-4">
				<div class="col form-group">
					<label for="" i18n>Mobile Number <sup>*</sup></label>
					<ngx-intl-tel-input
                            [cssClass]="'custom'"
                            [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="false"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[
                              SearchCountryField.Iso2,
                              SearchCountryField.Name
                            ]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="iso"
                            [maxLength]="15"
                            [phoneValidation]="true"
                            [separateDialCode]="separateDialCode"
                            [numberFormat]="PhoneNumberFormat.National"
                            name="usermobile"
                            formControlName="usermobile"
                          >
                          </ngx-intl-tel-input>

                          <div *ngIf="fmemberEError['usermobile'].invalid && (fmemberEError['usermobile'].dirty || fmemberEError['usermobile'].touched || fmemberEErrorss.submitted)" class="invalid">
                            <div *ngIf="fmemberEError['usermobile'].errors?.['required'] " i18n>
							Mobile Number is required.
                            </div>
                            <div *ngIf="fmemberEError['usermobile'].errors?.['validatePhoneNumber']" i18n>
							Mobile enter valid Phone number.
                            </div>
                          </div>


					<!-- <div class="row gx-1">
						<div class="col-md-5">
							<select class="form-control" formControlName="countryCode">
								<option *ngFor="let country of country_List;" value="{{'+'+country.phonecode}}">{{country.sortname}} (+ {{country.phonecode}} )</option>
							</select>
							<div *ngIf="fmemberEError['countryCode'].invalid && (fmemberEError['countryCode'].dirty || fmemberEError['countryCode'].touched || fmemberEErrorss.submitted)" class="invalid">
								<div *ngIf="fmemberEError['countryCode'].errors?.['required']"> Country Code is required. </div>
							</div>
						</div>
						<div class="col-md-7">
							<input type="number" class="form-control" formControlName="usermobile" />
							<div *ngIf="fmemberEError['usermobile'].invalid && (fmemberEError['usermobile'].dirty || fmemberEError['usermobile'].touched || fmemberEErrorss.submitted)" class="invalid">
								<div *ngIf="fmemberEError['usermobile'].errors?.['required']"> Mobile Number is required. </div>
								<div *ngIf="fmemberEError['usermobile'].errors?.['pattern']"> Mobile Number must be a valid 9-13 digit number. </div>
							</div>
						</div>
					</div> -->
				</div>
			</div>

			<div class="row">
				<div class="col">
					<label class="mb-1" i18n>Gender <sup>*</sup></label>

					<div *ngIf="fmemberEError['usergender'].invalid && (fmemberEError['usergender'].dirty || fmemberEError['usergender'].touched || fmemberEErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberEError['usergender'].errors?.['required']" i18n> This field is required. </div>
					</div>

					<div class="plans">

						<label class="plan basic-plan me-1" for="basic1">
							<input type="radio" formControlName="usergender" [value]="'Male'" id="basic1" />
							<div class="plan-content"> <img src="assets/img/male.png" alt="" />
								<div class="plan-details" i18n> Male </div>
							</div>
						</label>
						<label class="plan complete-plan me-1" for="complete1">
							<input type="radio" formControlName="usergender" [value]="'Female'" id="complete1" />
							<div class="plan-content"> <img src="assets/img/female.png" alt="" />
								<div class="plan-details" i18n> Female </div>
							</div>
						</label>
						<!-- <label class="plan neutral-plan" for="neutral1">
							<input type="radio" formControlName="usergender" [value]="'Neutral'" id="neutral1" />
							<div class="plan-content"> <img src="assets/img/neutral.png" alt="" />
								<div class="plan-details" i18n> Neutral </div>
							</div>
						</label> -->

					</div>
				</div>
			</div>

			<!-- <div class="row row-cols-md-1 row-cols-lg-1 mb-4 mb-md-4">
				<div class="col form-group">
					<label class="mb-1" i18n>Address <sup>*</sup></label>
					<textarea formControlName="useraddress" class="form-control" placeholder="Elephirny Road, Houe No 47/2A, 7CB" rows="3"></textarea>
				</div>
			</div> -->

      <div class="row row-cols-1 mb-3 mb-md-4 gy-2">
				<div class="col form-group">
					<label class="mb-1" i18n>Upload Registration ID Front <sup>*</sup></label>
					<div class="file-type-two">
					<input formControlName="registrationId" type="hidden" class="form-control">
					<input type="file" id="imageUpload" accept=".png, .jpg, .jpeg, .docx, .pdf" #fileInput (change)="uploadFile($event)" class="form-control">
					<i class="bi bi-cloud-arrow-up-fill"></i>
					<span><ng-container i18n="@@dragdrop">Drag & Drop or </ng-container> <span i18n="@@browsefile">browse</span></span>
				
				</div>
					<div *ngIf="fmemberEError['registrationId'].invalid && (fmemberEError['registrationId'].dirty || fmemberEError['registrationId'].touched || fmemberEErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberEError['registrationId'].errors?.['required']" i18n> Registration ID Front is required. </div>
					</div>

					<div class="img_preview">
						<div class="bx" *ngIf="regIdType=='png' || regIdType=='jpg' || regIdType=='jpeg'">
							<figure>
								<a href="{{regIdFront1}}" target="_blank"><img src="{{regIdFront1}}" alt="Registration img" class="img-fluid"></a>
								<!--{{_userdetailuser.registrationIds[0]}}-->
							</figure>
						</div>
						<div class="bx" *ngIf="regIdType=='pdf' || regIdType=='docx'">
							<figure><a href="{{regIdFront1}}" target="_blank"><i class="bi bi-file-pdf text-danger"></i></a></figure> <a href="javascript:void(0);" class="clos"><i class="bi bi-x"></i></a> </div>
					</div>
				</div>
				<div class="col form-group">
					<label class="mb-1" i18n>Upload Registration ID Back <sup>*</sup></label>
					<div class="file-type-two">
					<input formControlName="registrationIdBack" type="hidden" class="form-control">
					<input type="file" id="imageUpload" accept=".png, .jpg, .jpeg, .docx, .pdf" (change)="uploadFile1($event)" class="form-control">
					<i class="bi bi-cloud-arrow-up-fill"></i>
					<span><ng-container i18n="@@dragdrop">Drag & Drop or </ng-container> <span i18n="@@browsefile">browse</span></span>
				
				</div>
					<div *ngIf="fmemberEError['registrationIdBack'].invalid && (fmemberEError['registrationIdBack'].dirty || fmemberEError['registrationIdBack'].touched || fmemberEErrorss.submitted)" class="invalid">
						<div *ngIf="fmemberEError['registrationIdBack'].errors?.['required']" i18n> Registration ID Back is required. </div>
					</div>

					<div class="img_preview">
						<div class="bx" *ngIf="regIdTypeBack=='png' || regIdTypeBack=='jpg' || regIdTypeBack=='jpeg'">
							<figure>
								<a href="{{regIdBack1}}" target="_blank"><img src="{{regIdBack1}}" alt="Registration img" class="img-fluid"></a>
								<!--{{_userdetailuser.registrationIds[1]}}-->
							</figure>
						</div>
						<div class="bx" *ngIf="regIdTypeBack=='pdf' || regIdTypeBack=='docx'">
							<figure><a href="{{regIdBack1}}" target="_blank"><i class="bi bi-file-pdf text-danger"></i></a></figure> <a href="javascript:void(0);" class="clos"><i class="bi bi-x"></i></a> </div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col pro_btns">
					<button type="submit" class="btn btn-primary me-3" i18n>Update Profile</button>
					<button type="button" class="btn btn-transparent btn-danger"  aria-label="Close" (click)="hideEditModal()" i18n>Cancel</button> <!--data-bs-dismiss="offcanvas"-->
				</div>
			</div>
		</form>
	</div>
</div>