<h1 mat-dialog-title i18n>
    {{title}}
  </h1>
  
  <div mat-dialog-content>
    <p i18n>{{message}}</p>
  </div>
  
  <div mat-dialog-actions>
    <button mat-button (click)="onDismiss()" i18n>No</button>
    <button mat-raised-button color="primary" (click)="onConfirm()" i18n>Yes</button>
  </div>