import { Component, VERSION, OnInit,ViewChild } from "@angular/core";
import { NgxOtpInputConfig } from "ngx-otp-input";
import {FormControl,Validators,FormGroup,FormBuilder,AbstractControl,ValidatorFn,ValidationErrors,FormsModule,ReactiveFormsModule} from "@angular/forms";
import { UserAuthService } from "../../_services/user-auth.service";
import { ToastrService } from "ngx-toastr";
import { CustomValidators } from "src/app/_helpers/CustomValidators";

import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

import { Globals } from 'src/app/globals';
import { HeaderValueGetterParams } from 'ag-grid-community';
import { CookieService } from 'ngx-cookie-service';


declare var $: any;
@Component({
  selector: "app-update-profile",
  templateUrl: "./update-profile.component.html",
  styleUrls: ["./update-profile.component.css"],
  // imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, NgFor],
})

export class UpdateProfileComponent implements OnInit {
  selectedFiles: any;
  profilePic: File;
  _UploadPic: any;
  fileName: any;
  profile_image: string | ArrayBuffer;
  regIdType: any;
  regIdTypeBack: any;
  active = 1;
  _user: any;
  checkCurrentLesson1: boolean = false;
  showPasswordold: boolean = false;
  showPassword: boolean = false;
  showPasswordc: boolean = false;
  _getAccount: any;
  _editPAccount: any;
  __CountriesList: any;
  country_List: any;
  __StateList: any;
  state_List: any;
  __CityList: any;
  city_List: any;
  _otp: any;
  _otpId: any;
  isDisabled: boolean = true;
  emailnewsubmitbtn: boolean = false;
  userlang:any;
  maxDate = new Date().getFullYear()+'-'+String(new Date().getMonth()).padStart(2, '0')+'-'+new Date().getDate();
  
  @ViewChild("placesRef") placesRef!: GooglePlaceDirective;

  _userdetailuser: any = {
    firstName: "",
    lastName: "",
  };

  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      inputBox: "my-super-box-class",
      input: "my-super-class",
      inputFilled: "my-super-filled-class",
      inputDisabled: "my-super-disable-class",
      inputSuccess: "my-super-success-class",
      inputError: "my-super-error-class",
    },
  };

  countryCode:any;
  insuranceCompany_List:any=[];
  selectedCompany:any=[];
 
  insurerCompanies = new FormControl('',);
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  SearchCountryField = SearchCountryField;
  separateDialCode = true;
  PhoneNumberFormat = PhoneNumberFormat;
  iso:any="ch";
  regIdFront: any;
  regIdBack: any;
  private __MemberList: any;
  profilePicurl: unknown;
  constructor(private userAuthService: UserAuthService,private _formBuilder: FormBuilder,private toastrService: ToastrService,private cookieService:CookieService,private globals:Globals) { 
    this.userlang=this.cookieService.get('selectedCountryCode');
  }
  profileFormGroup = new FormGroup({
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    userDateofBirth: new FormControl("", [Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/),this.ageValidator()]),
    userAddress: new FormControl("", [Validators.required]),
    userGender: new FormControl("", [Validators.required]),
    userMarital: new FormControl("", [Validators.required]),
    userState: new FormControl("", [Validators.required]),
    userPostalCode: new FormControl("", [Validators.required,Validators.maxLength(6)]),
    userCity: new FormControl("", Validators.required),
    userCountry: new FormControl("Switzerland", [Validators.required]),
    profilePic: new FormControl(""),
    partnerId: new FormControl(""),
    agentId: new FormControl(""),
    registrationId: new FormControl("",[Validators.required]),
    registrationIdBack: new FormControl("",[Validators.required]),
    useremail: new FormControl(""),
    userMobile: new FormControl(""),
    companyName: new FormControl(""),
    website: new FormControl(""),
    noOfEmployee: new FormControl(""),
    companyId: new FormControl(""),
    legalEntity: new FormControl(""),
    type: new FormControl(""),
    source: new FormControl(""), 
    //insurerCompanies:new FormControl("",[]), 
    countryCodeShow: new FormControl(""), 
  }
  ,{
    updateOn: "change",
  });

  securityFormGroup = new FormGroup({
      oldPassword: new FormControl("", [Validators.required]),
      newPassword: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        CustomValidators.strong,
      ]),
      newPasswordC: new FormControl("", [Validators.required]),
    },{
      validators: [CustomValidators.mustMatch("newPassword", "newPasswordC")],
    }
  );

  EMailForm = new FormGroup({
    newEmail: new FormControl("", [Validators.required, Validators.email]),
  });

  MobileForm = new FormGroup({
    newMobile: new FormControl("", [Validators.required]),
    countryCode:new FormControl("41", [Validators.required]),
  });
  // newMobile: new FormControl("", [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{9,13}$")]),
  // countryCode:new FormControl("41", [Validators.required]),
  OTPFormGroup = new FormGroup({
    userotp: new FormControl("", [Validators.required]),
  });


  ageValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
          return null;
      }
      var date = new Date();
      date.setFullYear( date.getFullYear() - 16 );
      var selectedDate = new Date(control.value);
      var invalidAge:any
      if (date > selectedDate ) {
        invalidAge=true;
       } else {
        invalidAge=false;
      }
      return !invalidAge ? {invalidAge:true}: null;
    };
  }
 
  async ngOnInit(): Promise<void> {
    this.getInsuranceCompanies();
    await this.getCountries();
    this.getCurrentUser();
    await this.GetProfileDetail();
    await this.getStates();
    await this.getCities();
  }

  get firstf() {
    return this.securityFormGroup.controls;
  }
  get profilef() {
    return this.profileFormGroup.controls;
  }
  
  handeOtpChange(value: string[]): void {
    this.checkCurrentLesson1 = false;
  }

  handleFillEvent(value: string): void {
    this.checkCurrentLesson1 = true;
    this._otp = value;
  }

  getInsuranceCompanies(){
    this.userAuthService.getInsuranceCmpnayData({ 
    searchBy:""
    }).subscribe({
      next: (x: any) => {
        this.insuranceCompany_List = x.data.categoryList;
        
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
      },
      complete: () => {
      },
    });
  }

  async getCountries() {
    return new Promise((resolve, reject) => {
      this.__CountriesList = this.userAuthService.getcountries().subscribe({
        next: (x: any) => {
          this.country_List = x.data.countriesList;
          return resolve(true);
        },
        error: (err: Error) => {
          let errRes: any;
          errRes = err;
          return reject(true);
        },
        complete: () => {
        },
      });
    });
  }

  async getStates(state: any = "") {
    return new Promise((resolve, reject) => {
      this.__StateList = this.userAuthService
        .getstates(this.profileFormGroup.getRawValue().userCountry)
        .subscribe({
          next: (x: any) => {
            if (state == 1) {
              this.profileFormGroup.controls["userState"].setValue("");
              this.profileFormGroup.controls["userCity"].setValue("");
              this.city_List = [];
            }
            this.state_List = x.data.statesList;
            return resolve(true);
          },
          error: (err: Error) => {
            let errRes: any;
            errRes = err;
            return reject(true);
          },
          complete: () => {
          },
        });
    });
  }

  async getCities(state: any = "") {
    return new Promise((resolve, reject) => {
      this.__CityList = this.userAuthService
        .getcities(this.profileFormGroup.getRawValue().userState)
        .subscribe({
          next: (x: any) => {
            if (state == 1) {
              this.profileFormGroup.controls["userCity"].setValue("");
            }
            this.city_List = x.data.citiesList;
            return resolve(true);
          },
          error: (err: Error) => {
            let errRes: any;
            errRes = err;
            return reject(true);
          },
          complete: () => {
          },
        });
    });
  }


  setUserCategoryValidators(userType:any) {
    //companyName,website,noOfEmployee,companyId,legalEntity
    const ComapanyControl = this.profileFormGroup.get('companyName');
    const WebsiteControl = this.profileFormGroup.get('website');
    const EmployeeControl = this.profileFormGroup.get('noOfEmployee');
    const CompanyIDControl = this.profileFormGroup.get('companyId');
    const LeagalEntityControl = this.profileFormGroup.get('legalEntity');
    if (userType == "Company") {
      ComapanyControl.setValidators(Validators.required);
      WebsiteControl.setValidators([Validators.required,Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]);
     // WebsiteControl.setValidators([Validators.required,Validators.pattern("((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)")]);
    //  EmployeeControl.setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
     // CompanyIDControl.setValidators(Validators.required);
     // LeagalEntityControl.setValidators(Validators.required);
    }else{
      ComapanyControl.setValidators(null);
      WebsiteControl.setValidators(null);
      EmployeeControl.setValidators(null);
      CompanyIDControl.setValidators(null);
      LeagalEntityControl.setValidators(null);
    }
  }

  async getPresignedFile(filename:any) {
    return new Promise((resolve, reject) => { 
      this.__MemberList = this.userAuthService.getPresignedFile({
        key:filename
      }).subscribe({
       next: (x: any) => {
       
        return resolve(x.data.preSignedUrl);
       },
       error: (err: Error) => {
        return resolve(err);
       },
       complete: () => {
       },
     });
    });
    }

  async GetProfileDetail() {
    return new Promise((resolve, reject) => {
      this._getAccount = this.userAuthService.getuserDByToken().subscribe({
        next: async (x: any) => {
          let res = x.data;
          this._userdetailuser = res;

          let a=x.data.countryCode;
          if(x.data.countryCode.includes("+")){ 
            a=x.data.countryCode.substring(1);
          }

          let country=this.country_List.filter(function (country:any) { return country.phonecode ==a});
          this.iso=country[0].sortname;

          this.insurerCompanies.setValue(res.insurerCompanies);
          this.setUserCategoryValidators(res.type);
          if(res.countryCode!=null){
            this.MobileForm.controls['newMobile'].setValue(res.mobile);
            //this.MobileForm.controls["countryCode"].setValue(res.countryCode);
            this.profileFormGroup.controls["countryCodeShow"].setValue(res.countryCode);
          }
          
          var myarr:any;
          var myarr1:any;
          
          if(res.registrationIds!=null && res.registrationIds.length>0){
            if(res.registrationIds[0]!=null && res.registrationIds[0]!=""){
              
              this.regIdType =this.getExtension(res.registrationIds[0]);
            }
            if(res.registrationIds[1]!=null && res.registrationIds[1]!=""){
             
              this.regIdTypeBack = this.getExtension(res.registrationIds[1]);
            }
            this.profileFormGroup.controls["registrationId"].setValue(res.registrationIds[0]);
            this.profileFormGroup.controls["registrationIdBack"].setValue(res.registrationIds[1]);
            this.regIdFront = await this.getPresignedFile(res.registrationIds[0]);
      this.regIdBack = await this.getPresignedFile(res.registrationIds[1]);
          }
           
          this.profileFormGroup.controls["firstName"].setValue(res.firstName);
          this.profileFormGroup.controls["lastName"].setValue(res.lastName);
          this.profileFormGroup.controls["userDateofBirth"].setValue(res.dob);
          this.profileFormGroup.controls["userAddress"].setValue(res.address);
          if(res.gender!=null){
            this.profileFormGroup.controls["userGender"].setValue(res.gender);
          }
          if(res.maritalStatus!=null){
            this.profileFormGroup.controls["userMarital"].setValue(res.maritalStatus);
          }
          if(res.country!=null){
            this.profileFormGroup.controls["userCountry"].setValue(res.country);
          }
          if(res.state!=null){
            this.profileFormGroup.controls["userState"].setValue(res.state);
          }
          if(res.city!=null){
            this.profileFormGroup.controls["userCity"].setValue(res.city);
          }

          this.profileFormGroup.controls["userPostalCode"].setValue(res.postCode);
          this.profileFormGroup.controls["partnerId"].setValue(res.partnerId);
          this.profileFormGroup.controls["agentId"].setValue(res.agentId);
          this.profileFormGroup.controls["useremail"].setValue(res.email);
          this.profileFormGroup.controls["userMobile"].setValue(res.mobile);
          this.profileFormGroup.controls["profilePic"].setValue(res.profilePic);
          this.profilePicurl = await this.getPresignedFile(res.profilePic);
          if (res.type === "Company") {
            this.profileFormGroup.controls["companyName"].setValue(
              res.companyDetails.companyName
            );
            this.profileFormGroup.controls["website"].setValue(
              res.companyDetails.website
            );
            this.profileFormGroup.controls["noOfEmployee"].setValue(
              res.companyDetails.noOfEmployee
            );
            this.profileFormGroup.controls["companyId"].setValue(
              res.companyDetails.companyId
            );
            this.profileFormGroup.controls["legalEntity"].setValue(
              res.companyDetails.legalEntity
            );
          }
          this.profileFormGroup.controls["source"].setValue(res.source);
          this.profileFormGroup.controls["type"].setValue(res.type);
        
          return resolve(true);
        },
        error: (err: Error) => {
          let errRes: any;
          errRes = err;
          return reject(true);
        },
        complete: () => {},
      });
    });
  }

  UpdateProfileDetail(): any {
    
    if (this.profileFormGroup.invalid) {
      
      if(this.userlang=="en-US"){
        this.toastrService.error("Please fill All the required Fields."); 
      }
      if(this.userlang=="fr-CA"){
        this.toastrService.error("Veuillez remplir tous les champs obligatoires."); 
      } 
      return;
    }
    var regId:any=[];
    regId.push(this.profileFormGroup.getRawValue().registrationId);
    regId.push(this.profileFormGroup.getRawValue().registrationIdBack);
    var myarr:any;
    var myarr1:any;
    
    if(regId.length>0){
      if(regId[0]!=null && regId[0]!=""){
        myarr = regId[0].split("/");
        var myimg = myarr[myarr.length - 1] ? myarr[myarr.length - 1] : "";
        var myimgg = myimg.split(".");
        this.regIdType = myimgg[myimgg.length - 1]
          ? myimgg[myimgg.length - 1]
          : "";
      }
      if(regId[1]!=null && regId[1]!=""){
        myarr1 = regId[1].split("/");
        var myimg1 = myarr1[myarr1.length - 1] ? myarr1[myarr1.length - 1] : "";
        var myimgg1 = myimg1.split(".");
        this.regIdTypeBack = myimgg1[myimgg1.length - 1]
          ? myimgg1[myimgg1.length - 1]
          : "";
      }
    }

    this._getAccount = this.userAuthService
      .UpdateProfile(
        {
          firstName: this.profileFormGroup.getRawValue().firstName,
          lastName: this.profileFormGroup.getRawValue().lastName,
          dob: this.profileFormGroup.getRawValue().userDateofBirth,
          country: this.profileFormGroup.getRawValue().userCountry,
          state: this.profileFormGroup.getRawValue().userState,
          city: this.profileFormGroup.getRawValue().userCity,
          address: this.profileFormGroup.getRawValue().userAddress,
          postCode: this.profileFormGroup.getRawValue().userPostalCode,
          maritalStatus: this.profileFormGroup.getRawValue().userMarital,
          registrationIds: regId,
          partnerId: this.profileFormGroup.getRawValue().partnerId,
          agentId: this.profileFormGroup.getRawValue().agentId,
          gender: this.profileFormGroup.getRawValue().userGender,
          profilePic: this.profileFormGroup.getRawValue().profilePic,
          insurerCompanies:this.insurerCompanies.value,
          companyDetails: {
            companyName: this.profileFormGroup.getRawValue().companyName,
            website: this.profileFormGroup.getRawValue().website,
            noOfEmployee: this.profileFormGroup.getRawValue().noOfEmployee,
            companyId: this.profileFormGroup.getRawValue().companyId,
            legalEntity: this.profileFormGroup.getRawValue().legalEntity,
          },
          bankDetails: {
            iban: "",
            swift: "",
            bank: "",
            country: "",
            postCode: "",
            address: "",
            houseOwned: "",
          },
        },
        this._userdetailuser.userId
      )
      .subscribe({
        next: (x: any) => {
          let res = x.data;
          this.setUserCategoryValidators(res.type);
          this._userdetailuser = res;
          if(this.userlang=="en-US"){
            this.toastrService.success("Profile Updated Successfully."); 
          }
          if(this.userlang=="fr-CA"){
            this.toastrService.success("Mise à jour du profil réussie."); 
          } 
          setTimeout(() => {
            window.location.href = "user/my-account";
          }, 1000);
        },
        error: (err: Error) => {
          let errRes: any;
          errRes = err;
          if(this.userlang=="en-US"){
            this.toastrService.error(errRes.error.error.clientErrorMessage); 
          }
          if(this.userlang=="fr-CA"){
            this.toastrService.error(errRes.error.error.clientErrorMessageInFrench); 
          } 
        },
        complete: () => {},
      });
  }

  UpdateSecurityDetail(): any {
    if (this.securityFormGroup.invalid) {
      if(this.userlang=="en-US"){
        this.toastrService.error("Please fill All the required Fields."); 
      }
      if(this.userlang=="fr-CA"){
        this.toastrService.error("Veuillez remplir tous les champs obligatoires."); 
      } 
      return;
    }
    this._editPAccount = this.userAuthService
      .UpdatePassword({
        oldPassword: this.securityFormGroup.getRawValue().oldPassword,
        newPassword: this.securityFormGroup.getRawValue().newPassword,
      })
      .subscribe({
        next: (x: any) => {
          let res = x.data;
          this.setUserCategoryValidators(res.type);
          if(this.userlang=="en-US"){
            this.toastrService.success("Passwword changed Successfully."); 
          }
          if(this.userlang=="fr-CA"){
            this.toastrService.success("Le mot de passe a été modifié avec succès."); 
          } 
        },
        error: (err: Error) => {
          let errRes: any;
          errRes = err;
          if(this.userlang=="en-US"){
            this.toastrService.error(errRes.error.error.clientErrorMessage); 
          }
          if(this.userlang=="fr-CA"){
            this.toastrService.error(errRes.error.error.clientErrorMessageInFrench); 
          } 
        },
        complete: () => {},
      });
    
  }
  getCurrentUser(): any {
    let data: any = localStorage.getItem("user");
    if (data != null) {
      this._user = JSON.parse(data);
    }
    return this._user;
  }

  get newemailotpf() {
    return this.OTPFormGroup.controls;
  }
  VerifyEMailotpBtn(): any {
    this._editPAccount = this.userAuthService
      .VerifyOTPEmail({
        token: this._user.token,
        newEmail: this.EMailForm.getRawValue().newEmail,
        newEmailOtpDetails: {
          otpId: this._otpId,
          otp: this._otp,
        },
      })
      .subscribe({
        next: (x: any) => {
          let res = x.data;
          if(this.userlang=="en-US"){
            this.toastrService.success("Email Updated Successfully."); 
          }
          if(this.userlang=="fr-CA"){
            this.toastrService.success("E-mail mis à jour avec succès."); 
          } 

          this.GetProfileDetail();
          this.EMailForm.reset();
          $("#updateEmailOtp").modal("hide");
        },
        error: (err: Error) => {
          let errRes: any;
          errRes = err;
          if(this.userlang=="en-US"){
            this.toastrService.error(errRes.error.error.clientErrorMessage); 
          }
          if(this.userlang=="fr-CA"){
            this.toastrService.error(errRes.error.error.clientErrorMessageInFrench); 
          } 
        },
        complete: () => {},
      });
  }

  get newemailf() {
    return this.EMailForm.controls;
  }

  UpdateEMailBtn(): any {
    if (this.EMailForm.invalid) {
      this.emailnewsubmitbtn = true;
      if(this.userlang=="en-US"){
        this.toastrService.error("Email is required Field."); 
      }
      if(this.userlang=="fr-CA"){
        this.toastrService.error("L'e-mail est obligatoire. Champ."); 
      } 
      return;
    }
    this._editPAccount = this.userAuthService
      .UpdateEmail({
        newEmail: this.EMailForm.getRawValue().newEmail,
      })
      .subscribe({
        next: (x: any) => {
          let res = x.data;
          this._otpId = res.otpId;
          
          $("#verifyEmail").modal("hide");
          $("#updateEmailOtp").modal("show");
        },
        error: (err: Error) => {
          let errRes: any;
          errRes = err;
          if(this.userlang=="en-US"){
            this.toastrService.error(errRes.error.error.clientErrorMessage); 
          }
          if(this.userlang=="fr-CA"){
            this.toastrService.error(errRes.error.error.clientErrorMessageInFrench); 
          } 
        },
        complete: () => {},
      });
  }

  get newmobilef() {
    return this.MobileForm.controls;
  }

  //submited:any=false;
  UpdateMobileBtn(): any {
    //this.submited=true;
    if (this.MobileForm.invalid) {
      if(this.userlang=="en-US"){
        this.toastrService.error("Mobile number is required Field."); 
      }
      if(this.userlang=="fr-CA"){
        this.toastrService.error("Le numéro de portable est obligatoire. Champ."); 
      } 
      return;
    }

    var phone:any=this.MobileForm.getRawValue().newMobile;
    // var countryCode=phone.dialCode;
    // var phoneNumber=phone.number;

    var countryCode=(phone.dialCode);
    //var phoneNumber=phone.number;
    var phoneNumber=((phone.number).split(" ").join("")).substring(1);

    this._editPAccount = this.userAuthService
      .UpdateMobile({
        // newMobile: this.MobileForm.getRawValue().newMobile,
        // countryCode:this.MobileForm.getRawValue().countryCode,
        newMobile: phoneNumber,
        countryCode:countryCode,
      })
      .subscribe({
        next: (x: any) => {
          let res = x.data;
          this._otpId = res.otpId;
          $("#verifyContactNo").modal("hide");
          $("#updatephoneOtp").modal("show");
          // this.submited=false;
        },
        error: (err: Error) => {
          let errRes: any;
          errRes = err;
          $("#verifyContactNo").modal("show");
          $("#updatephoneOtp").modal("hide");
          if(this.userlang=="en-US"){
            this.toastrService.error(errRes.error.error.clientErrorMessage); 
          }
          if(this.userlang=="fr-CA"){
            this.toastrService.error(errRes.error.error.clientErrorMessageInFrench); 
          } 
        },
        complete: () => {},
      });
  }
  

  VerifyMobileotpBtn(): any {
    var phone:any=this.MobileForm.getRawValue().newMobile;
    // var countryCode=phone.dialCode;
    // var phoneNumber=phone.number;
    var countryCode=(phone.dialCode);
    //var phoneNumber=phone.number;
    var phoneNumber=((phone.number).split(" ").join("")).substring(1);
    this._editPAccount = this.userAuthService
      .VerifyOTPMobile({
        token: this._user.token,
        // newMobile: this.MobileForm.getRawValue().newMobile,
        // countryCode:this.MobileForm.getRawValue().countryCode,
        newMobile: phoneNumber,
        countryCode:countryCode,
        newMobileOtpDetails: {
          otpId: this._otpId,
          otp: this._otp,
        },
      })
      .subscribe({
        next: (x: any) => {
          let res = x.data;
          if(this.userlang=="en-US"){
            this.toastrService.success("Contact No. Updated Successfully."); 
          }
          if(this.userlang=="fr-CA"){
            this.toastrService.success("Numéro de contact mis à jour avec succès."); 
          } 
         // this.toastrService.success("Contact No. Updated Successfully");
          this.GetProfileDetail();
          this.MobileForm.reset();
          $("#updatephoneOtp").modal("hide");
        },
        error: (err: Error) => {
          let errRes: any;
          errRes = err;
          if(this.userlang=="en-US"){
            this.toastrService.error(errRes.error.error.clientErrorMessage); 
          }
          if(this.userlang=="fr-CA"){
            this.toastrService.error(errRes.error.error.clientErrorMessageInFrench); 
          } 
        },
        complete: () => {},
      });
  }

  uploadFile1(event:any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles !== undefined) {
      this.fileName = this.selectedFiles.item(0)?.name;
      const profile_imageh = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.profile_image = reader.result);
      reader.readAsDataURL(profile_imageh);
    } else {
      this.fileName = "";
    }
    var type = "registrationIdBack";
    this.updateProfilePic(type);
  }

  uploadFile(event:any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles !== undefined) {
      this.fileName = this.selectedFiles.item(0)?.name;
      const profile_imageh = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.profile_image = reader.result);
      reader.readAsDataURL(profile_imageh);
    } else {
      this.fileName = "";
    }
    this.updateProfilePic();
  }

  uploadProfileFile(event:any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles !== undefined) {
      this.fileName = this.selectedFiles.item(0)?.name;
      const profile_imageh = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.profile_image = reader.result);
      reader.readAsDataURL(profile_imageh);
    } else {
      this.fileName = "";
    }
    var type = "profilepic";
    this.updateProfilePic(type);
  }

  updateProfilePic(type: any = ""): void {
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      if (file) {
        this.profilePic = file;
      }
    }
    let docname = "";
    if (type == "profilepic") {
      docname = "Profile Picture";
    }else if(type=="registrationIdBack"){
      docname = "Registration ID Back";
    } else {
      docname = "Registration ID Front";
    }
    this._UploadPic = this.userAuthService
      .UploadDocument({
        docName: docname,
        userId: "",
        doc: this.profilePic,
        memberId: "",
      })
      .subscribe({
        next: (result: any) => {
          if (type == "profilepic") {
            this.profileFormGroup.controls["profilePic"].setValue(result.data.key);
            setTimeout(() => {
              this.UpdateProfileDetail();
            }, 500);
          } else if(type=="registrationIdBack"){
            this.profileFormGroup.controls["registrationIdBack"].setValue(result.data.key);
      this.regIdTypeBack=this.getExtension(result.data.key);
      this.regIdBack = result.data.docUrl;
          }else {
            this.profileFormGroup.controls["registrationId"].setValue(result.data.key);
            this.regIdType=this.getExtension(result.data.key);
            this.regIdFront =result.data.docUrl;
          }
          return;
        },
        error: (err: any) => {
    
          if (err.error.error.profile_pic) {
            if(this.userlang=="en-US"){
              this.toastrService.error(err.error.error.profile_pic); 
            }
            if(this.userlang=="fr-CA"){
              this.toastrService.error(err.error.error.profile_picInFrench); 
            } 
            this.toastrService.error(err.error.error.profile_pic, "");
          } else {
            if(this.userlang=="en-US"){
              this.toastrService.error(err.error.error.message); 
            }
            if(this.userlang=="fr-CA"){
              this.toastrService.error(err.error.error.messageInFrench); 
            } 
            //this.toastrService.error(err.error.message, "");
          }
        },
        complete: () => {},
      });
  }


  handleAddressChange(address: Address){
    this.profileFormGroup.controls['userAddress'].setValue(address.formatted_address);

    let postal_code:any = this.getComponentByType(address,"postal_code");
    let country:any = this.getComponentByType(address,"country");
    let state:any = this.getComponentByType(address,"administrative_area_level_1");
    let city:any = this.getComponentByType(address,"administrative_area_level_3");
    this.profileFormGroup.controls['userPostalCode'].setValue(postal_code?.long_name);
    this.profileFormGroup.controls['userCountry'].setValue(country?.long_name);
    this.profileFormGroup.controls['userState'].setValue(state?.long_name);
    this.profileFormGroup.controls['userCity'].setValue(city?.long_name);
  }
  
  getComponentByType(address:Address, type:any){
    if(!type)
    return '';
    if (!address || !address.address_components || address.address_components.length == 0)
      return '';
    type = type.toLowerCase();
    for (let comp of address.address_components) {
      if(!comp.types || comp.types.length == 0)
        continue;
      if(comp.types.findIndex(x => x.toLowerCase() == type) > -1)
      return comp;
    }
    return '';
  }
  getExtension(docurl:any){
    var myarr = docurl.split("/");
    console.log(myarr.length);
    var myimg=myarr[myarr.length-1]?myarr[myarr.length-1]:'';
    var myimgg = myimg.split(".");
   // console.log(myimgg.length);
    return myimgg[myimgg.length-1]?myimgg[myimgg.length-1]:'';
  }
}
