import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAdminRoutingModule } from './user-admin-routing.module';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { FamilyUpdateProfileComponent } from './family-update-profile/family-update-profile.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgxOtpInputModule } from 'ngx-otp-input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SharedModule } from '../shared/shared.module';
import { Globals } from 'src/app/globals';
//import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    LayoutComponent,
    DashboardComponent,
	  UpdateProfileComponent,
    FamilyUpdateProfileComponent,
   
	
  ],
  imports: [
    CommonModule,
    UserAdminRoutingModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule, 
    MatDialogModule,
    NgxOtpInputModule,
    NgbAccordionModule,
   MatSelectModule,
   MatFormFieldModule,
   MatInputModule,
   MatOptionModule,
   NgxIntlTelInputModule,
   GooglePlaceModule,
   SharedModule,
  //NgScrollbarModule
  ],
  exports: [
    MatButtonModule, 
    MatDialogModule
  ],
  providers:  [Globals],
  entryComponents: [ConfirmDialogComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class UserAdminModule { }
