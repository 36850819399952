import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AutologoutService } from 'src/app/_services/autologout.service';

declare var $: any;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, AfterViewInit {
  constructor(private autologout: AutologoutService){}

  ngAfterViewInit(): void {
    /**********SideMenu Toggle********/
    $('#sidebar_toggle').click(function(){
      $('.left-block').addClass('show-leftbar')
    })

    $('#close_sidebar').click(function(){
        $('.left-block').removeClass('show-leftbar')
    })
  }

  ngOnInit() {
    
}


}
