import { Component, HostListener } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import { Router, NavigationStart } from '@angular/router';
import { environment } from './../environments/environment';
import { CookieService } from 'ngx-cookie-service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'lty';

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: '',
  });

  thirdFormGroup = this._formBuilder.group({
    secondCtrl: '',
  });

  isOptional = false;
  showHead: boolean = false;
  showFooter: boolean=false;

  constructor(private _formBuilder: FormBuilder,private router: Router,private cookieService:CookieService) {
    //$localize'${this.title}';
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        var usr=localStorage.getItem("user");
        
        if (event['url'] == '/dashboard' || event['url'] == '/' || event['url'] == '/signin' || event['url'] == '/signup'  || event['url'] == '/forgotPassword' || event['url'] == '/otpVerify' || event['url'] == '/setPassword' || event['url'] == '/onboarding') {
          this.showHead = false;
          this.showFooter=false;
          let languageCode=undefined;
          languageCode=this.cookieService.get('selectedCountryCode');
          if(event['url'] == '/' && languageCode==undefined){
            this.cookieService.set('selectedCountryCode','fr-CA');
            this.cookieService.set('selectedCountryFlag','flag03.png');
           
          }
          
          if(usr!=null){
            this.router.navigate(['/user']);
          }
        } else {
          this.showHead = true;
          this.showFooter=true;
        }
      }
    });
  }

  isShow: boolean = false;
  topPosToStartShowing = 100;

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  onActivate(event:any) {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

}
